import { Modal, Steps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../App.context';
import { getOnlineBeneficiary, saveOnlineBeneficiary } from '../../api/NetworkingBeneficiaries';
import { getInstrumentsByCustomer } from '../../api/NetworkingCustomers';
import { saveEntityProperty } from '../../api/NetworkingEntityProperties';
import useCountries from '../../api/hooks/useCountries';
import useLookUpTables from '../../api/hooks/useLookUpTables';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { getResource } from '../../helpers/ResourcesHelper';
import ApprovalsNotification from '../approvals/ApprovalsNotification';
import Button from '../inputs/Button';
import BeneficiaryInformation from './BeneficiaryInformation';
import OptionalInformation from './OptionalInformation';
import PaymentDefault from './PaymentDefault';

const BeneficiayWizard = (props: any) => 
{
  const {showModal} = useContext(AppContext);
  const [showAFEXTC,setShowAFEXTC] = useState(false);
  const [externalBeneficiaryProperty, setExternalBeneficiaryProperty] = useState({
    Description: '',
    Value: '',
    UpdateToken: ''
  });
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [contactsAbleToApprove, setContactsAbleToApprove] =useState([
    {
      Email:'test@gmail.com',
      Name:'Test',
      label: 'Test',
      value:'test@gmail.com'
    }
  ]);
  const [beneficiary, setBeneficiary] = useState({
    BeneficiaryID: 0,
    BeneficiaryType: '',
    BeneficiaryName: '',
    BeneficiaryFirstName: '',
    BeneficiaryLastName: '',
    BeneficiaryShortName: '',
    AddressLine1: '',
    AddressLine2: '',
    CustomerID:0,
    City: '',
    CountryID: '',
    StateID: 0,
    StateName: '',
    Status: '',
    PostalCode: '',
    PreferredMethodOfPayment: '',
    PreferredDeliveryMethod: '',
    EmailAddress: '',
    PaymentLimit: 0,
    DetailsOfPayment: '',
    PaymentReference: '',
    PhoneNumber: '',
    PhoneExtension: '',
    FaxNumber: '',
    MobileNumber: '',
    ExternalBeneficiaryKey: '',
    UpdatedBy:'',
    UpdateToken:'',
    UpdatedByContactID:0
  });
  const [countries] = useCountries();
  const lookUpTables = useLookUpTables('Beneficiary Type');
  const [currentStep, setCurrentStep] = useState(0);
  const [outgoingInstruments, setOutgoingInstruments] = useState([]);
  const [beneFieldsStep1, validationsFieldsStep1] = useState(0);
  const [beneFieldsStep2, validationsFieldsStep2] = useState(0);
  const [sucessModal, setSucessModal] = useState(false);
  const [message, setMessage] = useState('');
  const { Step } = Steps;
  const ol_BeneficiaryAddNew = useOnlineRights('OL_BeneficiaryAddNew');
  const ol_BeneficiaryEdit = useOnlineRights('OL_BeneficiaryEdit');

  const onUpdateBeneficiary = (label: keyof typeof beneficiary, value: any) => {
    let bene = beneficiary;

    (bene[label] as any) = value;
    setBeneficiary(bene);
  };

  const onUpdateStep = (step: number) => {
    setCurrentStep(step);
  };

  const onNextClick = () => {
    switch (currentStep) {
      case 0:
        validationsFieldsStep1(beneFieldsStep1 + 1);
        break;
      case 1:
        validationsFieldsStep2(beneFieldsStep2 + 1);
        break;
      default: setCurrentStep(currentStep + 1); break;
    }

  };

  const steps = [
    {
      title: getResource('BeneficiaryInformation'),
      content: (
        <BeneficiaryInformation
          beneficiary={beneficiary}
          canEdit={props.readOnly != null ? !props.readOnly : ol_BeneficiaryAddNew || ol_BeneficiaryEdit}
          onUpdateBeneficiary={onUpdateBeneficiary}
          countries={countries}
          beneficiaryTypes={lookUpTables.BeneficiaryType}
          onValidate={onNextClick}
          beneFields={beneFieldsStep1}
          onUpdateStep={onUpdateStep}
        />
      )
    },
    {
      title: getResource('Tab.PaymentDefaults'),
      content: <PaymentDefault beneficiary={beneficiary} canEdit={props.readOnly != null ? !props.readOnly : ol_BeneficiaryAddNew || ol_BeneficiaryEdit}
        onUpdateBeneficiary={onUpdateBeneficiary} paymentMethods={outgoingInstruments} beneFields={beneFieldsStep2}
        onUpdateStep={onUpdateStep} />
    },
    {
      title: getResource('Message.OptionalInformation'),
      content: <OptionalInformation onUpdateBeneficiary={onUpdateBeneficiary} externalBeneficiaryProperty={externalBeneficiaryProperty} canEdit={props.readOnly != null ? !props.readOnly : ol_BeneficiaryAddNew || ol_BeneficiaryEdit} beneficiary={beneficiary} />
    }
  ];

  useEffect(() => {

    getInstrumentsByCustomer(props.customerID).then((response) => {
      if (response !== undefined && response.httpStatusCode === 200 && response.instruments !== undefined) {
        let instruments = response.instruments.filter((x: any) => x.InstrumentType === 'Outgoing');
        const formattedIntruments = instruments.map((c: any) => ({ ...c, label: c.Instrument, value: c.InstrumentTranslationLabel/*Instrument*/ }));

        setOutgoingInstruments(formattedIntruments);
      }
    });

    if(props.beneficiaryID != undefined && props.beneficiaryID > 0)
    {
      getOnlineBeneficiary(props.beneficiaryID)
          .then(response => {
            if (response != null && response.beneficiary != null && response.beneficiary.length > 0) {
              setBeneficiary(response.beneficiary[0]);
              if (response.beneficiary[0].EntityProperties != null) {
                let property = response.beneficiary[0].EntityProperties.find((p: any) => p.Description == 'External Beneficiary Key');
                if (property != null) {
                  setExternalBeneficiaryProperty(property);
                }
              }
            }
          });
      }
  }
, [props.beneficiaryID]);

  const onCancelClick = () =>
  {
    if(props.onCancelClick != undefined)
    {
        props.onCancelClick();
    }
    //setSucessModal(false)
  }

  const onPreviousClick = () => {
    validationsFieldsStep1(0);
    validationsFieldsStep2(0);
    setCurrentStep(currentStep - 1);
  };

  const validateBeneficiary = () =>{

    return true;

    /* else {
       showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
       return false;
     }*/

  }

  const onSaveClick =() =>
  {
    //validationsFieldsStep1(true)
    //validationsFieldsStep2(true)
    if(validateBeneficiary())
    {
      
      let afexSession = JSON.parse(localStorage.getItem('UserSession')!);
      if (afexSession.OnlineInterface != null && afexSession.OnlineInterface.includes('AFEX - Pass Thru')) {
          //-----------AFEX---------
          setShowAFEXTC(true);
      } else {
            saveBeneficiary();
          }
    }
  }

  const saveBeneficiary = () =>
  {
    var userSession = JSON.parse(localStorage.getItem('UserSession')!);
    beneficiary.UpdatedByContactID = userSession.ContactID;
    beneficiary.UpdatedBy = userSession.PersonID;
    beneficiary.CustomerID = userSession.CustomerID;
    beneficiary.Status = userSession.BeneApproveRequired ? "Pending approval" : beneficiary.Status;
      saveOnlineBeneficiary(beneficiary).then(
        (response) => {
            if (response !== undefined && response !== null) {
                let responseStatus = response.httpStatusCode
                switch (responseStatus) {
                    case 200:
                        if (response.ErrorMessage !== '') {

                          if (externalBeneficiaryProperty != null && beneficiary.ExternalBeneficiaryKey != null) {
                            let model = {
                              EntityId: response.beneficiaryId,
                              EntityType: 'Beneficiary',
                              PropertyDescription: 'External Beneficiary Key',
                              Value: beneficiary.ExternalBeneficiaryKey || '',
                              CreatedBy: userSession.PersonID,
                              UpdateToken: externalBeneficiaryProperty.UpdateToken || 0,
                            }

                            saveEntityProperty(model);

                          }

                            let requiresApproval = response.requiresApproval

                            if (requiresApproval === true) {

                                let contactsAbleToApprove = (response.contactsAbleToApprove == null || response.contactsAbleToApprove == '')  ? [] : response.contactsAbleToApprove

                                if (contactsAbleToApprove.length === 0) {
                                  showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_MissingApproval'), 'modal-message-md');
                                }
                                else {
                                  contactsAbleToApprove.push({ Email: 'All', Name: 'Send notification to All' });
                                  contactsAbleToApprove = contactsAbleToApprove.map((c: any) => ({ ...c, label: c.Name, value: c.Email }));

                                  setContactsAbleToApprove(contactsAbleToApprove);
                                  setRequiresApproval(requiresApproval);
                                }

                            } else {
                                let message = getResource('Js_Message_AprovedBeneficiary')
                                if (response.externalInterfaceMessage !== undefined && response.externalInterfaceMessage !== null
                                    && response.externalInterfaceMessage !== '') {
                                    let interfaceMessage = response.externalInterfaceMessage.split("\n").map(function (item: any, idx:any) {
                                        return (
                                            <span key={idx}>
                                                {item}
                                                <br />
                                            </span>
                                        )
                                    })
                                    message = <span> {message} <br /> {interfaceMessage}</span>
                                }
                              showModal(getResource('Js_Message_SubmitSucessful'), message, 'modal-message-md');
                              setTimeout(() => {
                                onCancelClick();
                              }, 2000);
                            }
                        } else {
                          showModal(getResource('Js_MessageError_TitleSubmit'), response.ErrorMessage);
                        }
                        break;
                    case 409:
                        showModal(getResource('Js_MessageError_TitleFormValidation'), response.httpErrorMessage);
                        break;
                    case (responseStatus >= 401 && responseStatus < 500):
                        showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
                        break;
                    case (responseStatus >= 500):
                      showModal(getResource('Js_MessageError_TitleSubmit'), 'Internal Server Error: ' + response.Message);
                        break;
                    default:
                    showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
                        break;
                }
              }
            }
      );
  }

  const handleCloseModalApprovalsNotification = () => {
    setRequiresApproval(false);
  };

  const onOkAFEX = () =>
  {
    setShowAFEXTC(false);
    saveBeneficiary();
  }

  const onCloseAFEX = () =>
  {
    setShowAFEXTC(false);
  }

  let actionButtons = []
  actionButtons.push(<Button type="secondary" onClick={() => onCancelClick()}>
    {getResource('Button.Cancel')}
  </Button>)
  if (currentStep > 0) {
    actionButtons.push(<Button type="primary" onClick={() => onPreviousClick()}>
      {getResource('Previous')}
    </Button>)
  }
  if (currentStep < steps.length - 1) {
    actionButtons.push(<Button type="primary" onClick={() => onNextClick()}>
      {getResource('Next')}
    </Button>)
  }
  if (currentStep === steps.length - 1 && (ol_BeneficiaryAddNew || ol_BeneficiaryEdit)) {
    actionButtons.push(<Button type="primary" onClick={() => onSaveClick()}>
      {getResource('Button.Save')}
    </Button>)
  }
  if (props.viewAndApprove !== undefined && props.viewAndApprove && currentStep === steps.length - 1) {
    actionButtons.push(<Button type="primary" onClick={() => props.approveFromBeneficiaryScreen()}>
      {getResource('Btn_Approve')}
    </Button>)
  }

  return (
    <div>
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className='screen-container'>
        <div className="row wizard-container">
          <div className='col-md-2 col-xs-0'> </div>
          <div className='col-md-8 col-xs-12'>
            {steps[currentStep].content}
          </div>
          <div className='col-md-2 col-xs-0'> </div>
        </div>

        <div className="row-actions">
          {actionButtons}
        </div>
      </div>
      <ApprovalsNotification
        title={getResource('BeneficiaryApproved')}
        entityName={beneficiary.BeneficiaryName}
        entityType="Beneficiary"
        showApprovalsNotification={requiresApproval}
        contactsAbleToApprove={contactsAbleToApprove}
        onClose={handleCloseModalApprovalsNotification}
        onCloseDetails={onCancelClick}
      />
      <Modal title={<h3 className="component-title">{getResource('AlertAFEX')}</h3>} centered visible={showAFEXTC} footer={null} onCancel={onCloseAFEX} maskClosable={false} width={'50%'} zIndex={1021}>
            <div>
              <div>
                <h4>{getResource('AFEX_Beneficiary_Message')}</h4>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button type="primary" onClick={onOkAFEX}>
                      {getResource('Button.Submit')}
                    </Button>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="col">
                    <Button type="primary" onClick={onCloseAFEX}>
                      {getResource('Button.Decline')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
    </div>
  );
}
export default BeneficiayWizard;