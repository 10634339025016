import { genericCallWithBody, genericGetWithParameters } from './Networking';

export async function getListOfCustomerExpiredTasks(customerID:number){
    try {
        let params = {
            'CustomerID':customerID
        };
        //let response = await genericGetWithParameters('TaskDefinition/GetListOfCustomerExpiredTasks',params);
        let response = await genericGetWithParameters('TaskDefinition/GetRequestedTasksByCustomer', params);
       
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function updateEntityTask(model:any){
    var method = 'PUT';
    var apiName = 'TaskDefinition/UpdateEntityTaskFields?';
    let response = await genericCallWithBody(method,apiName,model);
    return response;
}

export async function completeEntityTask(model: any) {
    var method = 'PUT';
    var apiName = 'TaskDefinition/CompleteEntityTaskFields';
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}