import { DeleteTwoTone, PaperClipOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import { Divider, Radio } from 'antd';
import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../../../App.context';
import { getBeneficiaryForDisbursementsScreen } from '../../../api/NetworkingBeneficiaries';
import { getFullDealHeader, saveDealHeader, validateCustomerAndContactLimits } from '../../../api/NetworkingDeals';
import { calculateWireItemFee } from '../../../api/NetworkingFees';
import { getUserDefinedPropertiesBySubEntityType } from '../../../api/NetworkingUserDefinedProperties';
import useCustomerCurrencies from '../../../api/hooks/useCustomerCurrencies';
import useForceUpdate from '../../../api/hooks/useForceUpdate';
import useInstruments from '../../../api/hooks/useInstruments';
import { usePrevious } from '../../../api/hooks/usePrevious';
import useUserDefinedProperties from '../../../api/hooks/useUserDefinedProperties';
import { formatDate, formatToCurrency } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import AttachmentsForm from '../../attachments/AttachmentsForm';
import Button from '../../inputs/Button';
import DatePicker from '../../inputs/DatePicker';
import Dropdown from '../../inputs/Dropdown';
import Input from '../../inputs/Input';
import ConfirmationModal from '../../others/ConfirmationModal';
import QuoteBox from '../../rates/quoteBox/QuoteBox';
import { ApprovalOption } from '../OrdersScreen';
import AddEditOrderTemplate from '../ordersTemplate/AddEditOrderTemplate';

interface OrdersDisbursementProps {
  buyCurrencyID: string;
  buyCurrencyItem: any;
  sellCurrencyID: string;
  valueDate: string;
  settlementMehotd: any;
  /*
    settlementMehotd:
    details: Array(3)
    0: {label: "Currency", description: "AUD"}
    1: {label: "Instrument", description: "Electronic Funds Transfer"}
    2: {label: "Delivery Method", description: "Wire Transfer"}
    length: 3
    [[Prototype]]: Array(0)
    label: "AUD - Electronic Funds Transfer - AUD"
    name: "AUD - Electronic Funds Transfer - AUD"
    value: "AUD;0;Electronic Funds Transfer;0"
  */
  onFinish: Function;
  onBack: Function;
  fromTemplate?: any;
  dealHeaderID?: number;
  fromBalance?: boolean;
}

export interface Disbursement {
  id?: number;
  token?: string;
  key: number;
  amount: number;
  otherAmount: number;
  fee: number;
  beneficiary: any;
  instrument: string;
  reference: string;
  entityProperties: any[];
  showProperties: boolean;
}
export interface ICustomerCurrencies {
  payment: any[];
  settlement: any[];
}
const OrdersDisbursement: FunctionComponent<OrdersDisbursementProps> = (props) => {
  const [addProperties] = useUserDefinedProperties('OutgoingFund - Online');
  const [instruments, instrumentsForNonAccounts] = useInstruments();
  const forceUpdate = useForceUpdate();

  const [firstTime, setFirstTime] = useState(true);

  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [updating, setUpdating] = useState(false);
  const [newAdded, setNewAdded] = useState(0);
  const prevNewAdded = usePrevious(newAdded);
  const prevBuyCurrency = usePrevious(props.buyCurrencyID);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiariesList, setBeneficiariesList] = useState([]);
  const [amount, setAmount] = useState(0.00);
  const [fees, setFees] = useState(0.00);
  const [lockedSide, setLockedSide] = useState('sell')
  const [disburses, setDisburses] = useState<Disbursement[]>([]);
  const [quoted, setQuoted] = useState(false);
  const refQuoted = useRef(quoted);

  const [amountCalc, setamountCalc] = useState(0);
  const [customerRate, setCustomerRate] = useState(0);
  const [marketRate, setMarketRate] = useState(0);
  const [rateQuoteID, setRateQuoteID] = useState('');
  const [profit, setProfit] = useState(0);
  const [coverCustomerID, setCoverCustomerID] = useState(0);
  const [externalInterface, setExternalInterface] = useState('');
  const [externalInterfaceType, setExternalInterfaceType] = useState('');
  const [rateTerms, setRateTerms] = useState('');

  const [properties, setProperties] = useState([] as any[]);
  const [showAddProp, setShowAddProp] = useState<any[]>([]);

  const [showAttachmentForm, setShowAttachmentForm] = useState(false);
  const [currentAttachmentProp, setCurrentAttachmentProp] = useState<any>({ id: -1, description: '', currentDisbursementID: -1 });

  // ---  Modal Add Template -------------------------------------------------------------------r2d2--
  const [showAddLikeTemplateModal, setShowAddLikeTemplateModal] = useState(false);
  //--------------------------------------------------------------------------------------------------
  const [submit, setSubmit] = useState(false);
  const [updateToken, setUpdateToken] = useState(0);
  const [incomingFunds, setIncomingFunds] = useState([] as any[]);

  // --- For Input Number Scale ----------------------------------------------------------------------
  const [customerCurrencies] = useCustomerCurrencies();
  const [sellScale, setSellScale] = useState(2);
  const [buyScale, setBuyScale] = useState(2);
  const [currencyGroup, setCurrencyGroup] = useState('');

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [dealToConfirmAcceptance, setDealToConfirmAcceptance] = useState({} as any);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState('');

  const [confirmationMessage, setConfirmationMessage] = useState({} as any);

  //after contact limit validation
  const [objDealHeaderAfterValidation, setObjDealHeaderAfterValidation] = useState({});
  const [showConfirmModalValidation, setShowConfirmModalValidation] = useState<boolean>(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [allowHoldFundsInstrument, setAllowHoldFundsIntrument] = useState(false);

  useEffect(() => {
    if (newAdded > 0 && prevNewAdded !== newAdded) {
      addListOfProps(disburses, false, []);
      if (props.dealHeaderID != null && props.dealHeaderID > 0) {
        getFullDealHeader(props.dealHeaderID).then((dealResponse: any) => {
          const beneObject = dealResponse.OutgoingFunds != null ? (dealResponse.OutgoingFunds.length > 0 ? dealResponse.OutgoingFunds : []) : [];
          let helper = [] as any;
          beneObject.forEach((element: any, index: number) => {
            if (element.BeneficiaryID != null) {
              helper.push({
                key: index + 1,
                amount: element.PaymentAmount,
                beneficiary: {
                  OutgoingFundsID: element.OutgoingFundsID,
                  BankAccountID: element.BeneficiaryBankAccountID,
                  BeneficiaryBankAccountName: '',
                  BeneficiaryID: element.BeneficiaryID,
                  BeneficiaryName: element.Name,
                  BeneficiaryStatus: element.Status,
                  BeneficiaryType: element.BeneficiaryType,
                  CountryName: element.CountryName,
                  Description: '',
                  PreferredDeliveryMethod: '',
                  PreferredMethodOfPayment: element.PaymentInstrument,
                  Value: element.BeneficiaryID + '|' + element.BeneficiaryBankAccountID,
                  label: '',
                  value: element.BeneficiaryID + '|' + element.BeneficiaryBankAccountID,
                  UpdateToken: element.UpdateToken,
                },
                instrument: element.PaymentInstrument,
                reference: element.PaymentReference,
                entityProperties: element.EntityProperties != null ? element.EntityProperties : [],
                fee: 0
              });
            }
          });
          setIncomingFunds(dealResponse.IncomingFunds)
          setUpdateToken(dealResponse.UpdateToken)
          setDisburses(helper);
        });
      }
    }
  }, [updating, newAdded]);

  useEffect(() => {
    if (props.buyCurrencyID !== '') {
      const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];

      getBeneficiaryForDisbursementsScreen(customerID, props.buyCurrencyID).then((jsonBene: any) => {
        if (jsonBene.beneficiaries) {
          const finalJsonBene = jsonBene.beneficiaries.filter((item: any) => item.BeneficiaryStatus === 'Authorized').map((c: any) => ({ ...c, label: c.Description, value: c.Value, BeneficiaryType: 0 }));
          finalJsonBene.push({ label: getResource('SelectBeneficiary'), value: '' });
          setBeneficiaries(finalJsonBene);

          /* para que salga uno a la primera */
          if (props.fromTemplate === undefined) {
          getUserDefinedPropertiesBySubEntityType('OutgoingFund', 0, 0).then(
            (response) => {
              let properties: any[] = [];
              if (response.properties !== undefined) {
                response.properties.forEach((element: any) => {
                  if (element['ExtUserPropertyID$'] !== null) {
                    properties.push(element);
                  }
                });
              }
              addARowToList(properties, null, true);
            }
          );
          }
          // ----------------------------------
        }
        else {
          getUserDefinedPropertiesBySubEntityType('OutgoingFund', 0, 0).then(
            (response) => {
              let properties: any[] = [];
              if (response.properties !== undefined) {
                response.properties.forEach((element: any) => {
                  if (element['ExtUserPropertyID$'] !== null) {
                    properties.push(element);
                  }
                });
              }
              addARowToList(properties, null, true);
            }
          );
        }
      });
    }
    if (props.buyCurrencyItem != null) {
      setBuyScale(props.buyCurrencyItem.NumberOfDecimals);
      setCurrencyGroup(props.buyCurrencyItem.CurrencyType);
    }
  }, [props.buyCurrencyID])

  useEffect(() => {
    let listInstruments = instruments as [];
    if (props.fromBalance) {
      setBeneficiariesList(beneficiaries);
    }
    else {
      let holdInst = listInstruments.find((i: any) => i.value === "Hold fund in Client's account");
      if (holdInst) {
        let beneList: any = beneficiaries as [];
        beneList.push({ label: "Hold fund in Client's account", value: '0', BeneficiaryID: 0, BankAccountID: 0, BeneficiaryType: 2 });
        setBeneficiariesList(beneList);
        setAllowHoldFundsIntrument(true);
      }
      else {
        setBeneficiariesList(beneficiaries);
      }
    }
  }, [instruments, beneficiaries])

  useEffect(() => {
    if (props.sellCurrencyID != null && props.sellCurrencyID !== '' && (customerCurrencies as ICustomerCurrencies).settlement != null) {
      const sellcurrencyItemAux = (customerCurrencies as ICustomerCurrencies).settlement.find((currency: any) => currency.value === props.sellCurrencyID);
      if (sellcurrencyItemAux != null) {
      setSellScale(sellcurrencyItemAux.NumberOfDecimals);
      }
      else {
        setSellScale(2);
      }
    }
  }, [customerCurrencies])

  useEffect(() => {
    if (beneficiaries.length > 0 && props.fromTemplate !== undefined) {
      if (props.fromTemplate.Disbursements !== undefined) {
        getUserDefinedPropertiesBySubEntityType('OutgoingFund', 0, 0).then(
          (response) => {
            let properties: any[] = [];
            if (response.properties !== undefined) {
              response.properties.forEach((element: any) => {
                if (element['ExtUserPropertyID$'] !== null) {
                  properties.push(element);
                }
              });
            }
            addARowToList(properties, props.fromTemplate.Disbursements, true);
          }
        );
      }
    }
  }, [props.fromTemplate, beneficiaries]);

  useEffect(() => {
    if (!firstTime && disburses.length > 0) {
      addListOfProps(disburses, true, addProperties as any[]);
    }
  }, [firstTime])

  useEffect(() => {
    if (props.fromBalance !== undefined) {
      if (props.fromBalance === true) {
        setLockedSide('buy');
      }
    }
  }, [props.fromBalance])

  const addListOfProps = (disburses: any[], firstTime: boolean, addPropertiesFT: any[]) => {
    if (firstTime) {
      let auxShowAddPropFT: any[] = [];
      disburses.forEach((dis: Disbursement) => {
        auxShowAddPropFT.push({
          id: dis.key,
          show: false,
          rows: renderProperties(addPropertiesFT as any[], dis.entityProperties, dis.key),
        });
      });
      setShowAddProp(auxShowAddPropFT);
    } else {
      const auxShowAddProp = [...showAddProp];
      auxShowAddProp.push({
        id: disburses[disburses.length - 1].key,
        show: false,
        rows: renderProperties(addProperties as any[], disburses[disburses.length - 1].entityProperties, disburses[disburses.length - 1].key),
      });
      setShowAddProp(auxShowAddProp);
    }
  }

  const deleteADisbursementRow = (idRow: number) => {
    if (!quoted) {
      //console.log("ID a eliminar: " + idRow);
      const auxDeleteDisbursement = [...disburses];
      let tempAmount = 0;
      disburses.forEach((disbursement, index) => {
        if (disbursement.key === idRow) {
          auxDeleteDisbursement.splice(index, 1);
          tempAmount = disbursement.amount;
        }
      });
      setDisburses(auxDeleteDisbursement);
      setAmount(amount - tempAmount);
      forceUpdate();
    }
  }

  const validateEntities = (disbursement: Disbursement) => {
    let valid = true;
    disbursement.entityProperties.forEach(entity => {
      /*
        AmountLimit: 200
        Attachments: []
        AttachmentsRequired: false
        DataType: "Text"
        DateValue: null
        Description: "Copy of invoice and number"
        EntityPropertyID: null
        IsValid: true
        LookupTableAttachmentsRequired: false
        Mandatory: true
        NumValue: null
        TextValue: ""
        UserDefinedPropertyID: 3181
      */
      if (entity.Mandatory === true) {
        switch (entity.DataType) {
          case 'Text': {
            if (entity.TextValue === undefined || entity.TextValue === null || entity.TextValue === '') {
              valid = false;
            }
              break;
            }
            case 'Numeric': {
              if (entity.NumValue === undefined || entity.NumValue === null || entity.NumValue === '' || entity.NumValue === 0) {
                valid = false;
              }
              break;
            }
            case 'DateTime': {
              if (entity.DateValue === undefined || entity.DateValue === null || entity.DateValue === '') {
                valid = false;
              }
              break;
            }
          }
        }
        else {
          if (entity.AmountLimit !== undefined && entity.AmountLimit !== null && entity.AmountLimit !== 0) {
            if (disbursement.amount * props.buyCurrencyItem.BaseCurrencyDirectRate > entity.AmountLimit) {
              if (entity.ExtMandatory === true) {
                switch (entity.DataType) {
                  case 'Text': {
                    if (entity.TextValue === undefined || entity.TextValue === null || entity.TextValue === '') {
                      valid = false;
                    }
                    break;
                  }
                  case 'Numeric': {
                    if (entity.NumValue === undefined || entity.NumValue === null || entity.NumValue === '' || entity.NumValue === 0) {
                      valid = false;
                    }
                    break;
                  }
                  case 'DateTime': {
                    if (entity.DateValue === undefined || entity.DateValue === null || entity.DateValue === '') {
                      valid = false;
                    }
                    break;
                  }
                }
              }
              if (entity.AttachmentsRequired) {
                if (entity.Attachments.length === 0) {
                  valid = false;
                }
              }
            }
          }
        }
    });
    return valid;
  }

  const validateEntity = (disbursement: Disbursement, entity: any) => {
    let valid = true;
    /*
      AmountLimit: 200
      Attachments: []
      AttachmentsRequired: false
      DataType: "Text"
      DateValue: null
      Description: "Copy of invoice and number"
      EntityPropertyID: null
      IsValid: true
      LookupTableAttachmentsRequired: false
      Mandatory: true
      NumValue: null
      TextValue: ""
      UserDefinedPropertyID: 3181
    */
    if (entity.Mandatory === true) {
      switch (entity.DataType) {
        case 'Text': {
          if (entity.TextValue === undefined || entity.TextValue === null || entity.TextValue === '') {
            valid = false;
          }
          break;
        }
        case 'Numeric': {
          if (entity.NumValue === undefined || entity.NumValue === null || entity.NumValue === '' || entity.NumValue === 0) {
            valid = false;
          }
          break;
        }
        case 'DateTime': {
          if (entity.DateValue === undefined || entity.DateValue === null || entity.DateValue === '') {
            valid = false;
          }
          break;
        }
      }
    }
    else {
        if (entity.AmountLimit !== undefined && entity.AmountLimit !== null && entity.AmountLimit !== 0) {
          if (disbursement.amount * props.buyCurrencyItem.BaseCurrencyDirectRate > entity.AmountLimit) {
            if (entity.ExtMandatory === true) {
              switch (entity.DataType) {
                case 'Text': {
                  if (entity.TextValue === undefined || entity.TextValue === null || entity.TextValue === '') {
                    valid = false;
                  }
                  break;
                }
                case 'Numeric': {
                  if (entity.NumValue === undefined || entity.NumValue === null || entity.NumValue === '' || entity.NumValue === 0) {
                    valid = false;
                  }
                  break;
                }
                case 'DateTime': {
                  if (entity.DateValue === undefined || entity.DateValue === null || entity.DateValue === '') {
                    valid = false;
                  }
                  break;
                }
              }
            }
            if (entity.AttachmentsRequired) {
              if (entity.Attachments.length === 0) {
                valid = false;
              }
            }
          }
        }
      }

    return valid;
  }

  const valdiatePrevRows = (fromButton: boolean, fromQuote: boolean): [boolean, number[]] => {
    let valid = true;
    let lines: number[] = [];
    if (disburses.length === 0 && !fromButton) {
      valid = false;
    } else {
      disburses.forEach((disbursement, index) => {
        let validEntitites = (fromQuote ? validateEntities(disbursement) : true);
        if (disbursement.beneficiary.value === '' ||
          disbursement.instrument === '' ||
          disbursement.amount === 0 || isNaN(disbursement.amount) ||
          !validEntitites) {
          valid = false;
          lines.push(index + 1);
        }
        if (fromQuote) {
          disbursement.showProperties = !validEntitites;
        }
      });
    }
    return [valid, lines];
  }

  const addARowToList = (properties: any[], disbursements: any[] | null, fromButton: boolean) => {
    let [isvalid, lines] = valdiatePrevRows(fromButton, false);
    if (!quoted && isvalid) {
      //console.log("--disburses list-> ", disburses);
      let uniqueID = disburses.length > 0 ? (disburses[disburses.length - 1].key + 1) : 1;
      let newDisbursment: any;

      if (disbursements !== null) {
        const auxDisburses1 = [...disburses];
        disbursements.forEach((disbursement, index) => {
          let getBene: any;
          ([...beneficiaries] as any[]).forEach(bene => {
            if (bene.BeneficiaryID === disbursement.BeneficiaryID && bene.BankAccountID === disbursement.BeneficiaryBankAccountID) {
              getBene = bene;
            }
          });
          //entityProperties -----------------
          let listOfPropertiesByDisbursement: any[] = [];
          properties.forEach((propertiesHelper, index) => {
            let propHelper = {
              Attachments: [],
              AttachmentsRequired: propertiesHelper['ExtAttachmentsRequired$'],
              AmountLimit: propertiesHelper['ExtAmountLimit$'],
              DataType: propertiesHelper['Data Type'],
              DateValue: null,
              Description: propertiesHelper['Description'],
              EntityPropertyID: null,
              LookupTableAttachmentsRequired: false,
              Mandatory: propertiesHelper['Mandatory'],
              ExtMandatory: propertiesHelper['ExtMandatory$'],
              NumValue: null,
              TextValue: '',
              UserDefinedPropertyID: propertiesHelper['ID'],
              IsValid: true,
            }
            listOfPropertiesByDisbursement.push(propHelper);
          });
          if (getBene === undefined) {
            getBene = {
              key: uniqueID,
              amount: NaN,
              otherAmount: NaN,
              beneficiary: { BeneficiaryID: 0, value: '' },
              instrument: '',
              reference: '',
              fee: 0,
              entityProperties: listOfPropertiesByDisbursement,
              showProperties: false
              //attachments: [{ id: '', value: '', files: [{ id: '', fileName: '', description: '' }] }]
            };
          }
          // ---------------------------------

          newDisbursment = {
            key: (index + 1),
            amount: disbursement.SellAmount,
            otherAmount: 0,
            beneficiary: getBene,
            instrument: disbursement.Instrument,
            reference: disbursement.PaymentReference,
            fee: disbursement.WireItemFee,
            entityProperties: listOfPropertiesByDisbursement,
            showProperties: false,
          }
          auxDisburses1.push(newDisbursment);
        });

        //addListOfProps(auxDisburses1, true, properties);
        setDisburses(auxDisburses1);
        setFirstTime(false);
      } else {
        //entityProperties -----------------
        let listOfPropertiesByDisbursement: any[] = [];
        properties.forEach((propertiesHelper, index) => {
          let propHelper = {
            Attachments: [],
            AttachmentsRequired: propertiesHelper['ExtAttachmentsRequired$'],
            AmountLimit: propertiesHelper['ExtAmountLimit$'],
            DataType: propertiesHelper['Data Type'],
            DateValue: null,
            Description: propertiesHelper['Description'],
            EntityPropertyID: null,
            LookupTableAttachmentsRequired: false,
            Mandatory: propertiesHelper['Mandatory'],
            ExtMandatory: propertiesHelper['ExtMandatory$'],
            NumValue: null,
            TextValue: '',
            UserDefinedPropertyID: propertiesHelper['ID'],
            IsValid: true,
          }
          listOfPropertiesByDisbursement.push(propHelper);
        });
        // ---------------------------------
        newDisbursment = {
          key: uniqueID,
          amount: NaN,
          otherAmount: NaN,
          beneficiary: { BeneficiaryID: 0, value: '' },
          instrument: '',
          reference: '',
          fee: 0,
          entityProperties: listOfPropertiesByDisbursement,
          showProperties: false
          //attachments: [{ id: '', value: '', files: [{ id: '', fileName: '', description: '' }] }]
        }

        const auxDisburses2 = [...disburses];
        auxDisburses2.push(newDisbursment);
        setDisburses(auxDisburses2);
        setNewAdded(newAdded + 1);
        setSubmit(false);
      }


      // Properties ---------------------------------------------------

      // --------------------------------------------------------------

    } else {
      showModal(getResource('Title.Warning'), getResource('OnLines') + lines.join() + '. ' + getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
      setSubmit(true);
    }
  }

  const onUpdateOrderDisbursement = (id: number, name: string, value: any, otherValue?: any) => {
    const listauxDis = [...disburses];
    let totalAmount = 0;
    listauxDis.forEach((dis, index) => {
      if (dis.key === id) {
        switch (name) {
          case 'Amount': {
            setamountCalc(0);
            //dis.amount = parseFloat(value.replace(/,/g, ""));
            dis.amount = value;
            break;
          }
          case 'Beneficiary': {
            dis.beneficiary = dis.beneficiary.BeneficiaryID !== value.BeneficiaryID ? value : dis.beneficiary;
            if (dis.beneficiary.BankAccountID > 0) {
              let auxInstruments = instruments as any[];
              let values = auxInstruments.filter(i => i.value.includes('Electronic Funds Transfer'));

              if (values.length > 0) {
                dis.instrument = values[0].InstrumentTranslationLabel//Instrument
              }

            } else {
              /** "Hold fund in Client's account" */
              if (dis.beneficiary.BeneficiaryID === 0) {
                dis.instrument = "Hold fund in Client's account";
              }
              else {
                dis.instrument = '';
              }

            }
            forceUpdate();
            break;
          }
          case 'Instrument': {
            dis.instrument = value;
            break;
          }
          case 'Reference': {
            dis.reference = value;
            break;
          }
          case 'EntityProperties': {
            dis.entityProperties = otherValue;
            break;
          }
        }
      }
      totalAmount = totalAmount + dis.amount;
    });

    setAmount(totalAmount);
    setDisburses(listauxDis);
  }

  const voidOrder = () => {
  }

  const saveDeal = async (status: string, rateQuoteID: string, customerRate: number, marketRate: number, profit: number, coverCustomerID: number, externalInterface: string, externalInterfaceType: string, rateTerms: string, jsonProperties: string) => {

    showLoadingModal();
    //Status
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    let dealStatus = userSession.DealApproveRequired ? 'Pending for Approval' : 'Accepted';
    if (status === 'Work in Progress') {
      dealStatus = status;
    }

    let outgoingFunds: any[] = [];

    disburses.forEach((disbursement: Disbursement, index: number) => {
      let outgoing = {
        DealHeaderID: props.dealHeaderID != null ? props.dealHeaderID : 0,
        ValueDate: formatDate(props.valueDate),
        UpdateToken: disbursement.beneficiary.UpdateToken != null ? disbursement.beneficiary.UpdateToken : 0,
        BeneficiaryID: disbursement.beneficiary.BeneficiaryID,
        BeneficiaryBankAccountID: disbursement.beneficiary.BankAccountID,
        BeneficiaryType: disbursement.beneficiary.BeneficiaryType,
        Equivalent: lockedSide === 'sell' ? formatToCurrency(disbursement.otherAmount.toString(), undefined, sellScale) : formatToCurrency(disbursement.amount.toString(), undefined, sellScale),
        EntityProperties: disbursement.entityProperties,
        EntityPropertiesJson: JSON.stringify(disbursement.entityProperties),
        OutgoingFundsID: disbursement.beneficiary.OutgoingFundsID != null ? disbursement.beneficiary.OutgoingFundsID : 0,
        PaymentAmount: lockedSide === 'sell' ? formatToCurrency(disbursement.amount.toString(), undefined, sellScale) : formatToCurrency(disbursement.otherAmount.toString(), undefined, sellScale),
        PaymentInstrument: disbursement.instrument,
        PaymentReference: disbursement.reference,
        ShowProperties: true,
        Status: 'Pending',
        WireItemFee: formatToCurrency(disbursement.fee.toString(), undefined, sellScale),
        WireItemFeeCurrencyID: props.buyCurrencyID,
      }
      outgoingFunds.push(outgoing);
    });

    /*
      details: Array(3)
      0: {label: "Currency", description: "AUD"}
      1: {label: "Instrument", description: "Electronic Funds Transfer"}
      2: {label: "Delivery Method", description: "Wire Transfer"}
    */
    let auxDetailsSettlementMehotd = props.settlementMehotd.details;

    let actualInstrument = '';
    let actualDeliveryMethod = '';

    if (auxDetailsSettlementMehotd != null) {
      auxDetailsSettlementMehotd.forEach((element: any) => {
        switch (element.label) {
          case 'Instrument': {
            actualInstrument = element.description;
            break
          }
          case 'Delivery Method': {
            actualDeliveryMethod = element.translationlabel//element.description;
            break
          }
        }
      });
    } else if (incomingFunds != null) {
      incomingFunds.forEach((element: any) => {
        actualInstrument = element.SettlementInstrument;
        actualDeliveryMethod = element.SettlementDeliveryMethod;
      });
    }

    const totalPayments = sumPayments();
    const finalAmount = totalPayments.toFixed(sellScale);
    const finalOtherAmount = amountCalc.toFixed(sellScale);

    let objDealHeader: any = {
      BuyAmount: (lockedSide === 'sell') ? finalOtherAmount : finalAmount,
      BuyCurrency: (props.fromTemplate !== undefined ? props.fromTemplate.SellCurrency : props.sellCurrencyID),
      CoverCustomerID: coverCustomerID,
      CustomerContactID: JSON.parse(localStorage.getItem('UserSession')!).ContactID,
      CreatedBy: localStorage.getItem('UserID'),
      CustomerID: JSON.parse(localStorage.getItem('UserSession')!).CustomerID,
      CustomerRate: customerRate,
      CustomerRefNo: '',
      ExternalInterface: externalInterface,
      ExternalInterfaceType: externalInterfaceType,
      DealHeaderID: props.dealHeaderID != null ? props.dealHeaderID : 0,
      LockedSide: lockedSide === 'sell' ? 'Sell' : 'Buy',
      MarketRate: marketRate,
      OutgoingFunds: outgoingFunds,
      ProfitAmount: profit,
      RateQuoteID: rateQuoteID,
      SellAmount: lockedSide === 'sell' ? finalAmount : finalOtherAmount,
      SellCurrency: (props.fromTemplate !== undefined ? props.fromTemplate.BuyCurrency : props.buyCurrencyID),
      Status: dealStatus,
      DealOrigin: "Online Deal",
      DealType: 1,
      UpdateBuyAmountField: lockedSide === 'sell' ? true : false,
      UpdatedBy: localStorage.getItem('UserID'),
      UpdateToken: updateToken,
      ValueDate: formatDate(props.valueDate),
      SettlementDeliveryMethods: actualDeliveryMethod,
      JsonProperties: jsonProperties,
      IncomingFunds: [{
        IncomingFundsID: incomingFunds[0] != null ? incomingFunds[0].IncomingFundsID : 0,
        Amount: lockedSide === 'sell' ? finalOtherAmount : finalAmount,
        BankAccountID: 0,
        SettlementInstrument: actualInstrument,
        SettlementDeliveryMethod: actualDeliveryMethod
      }]
    }

    setObjDealHeaderAfterValidation(objDealHeader);

    let responseLimit = await validateCustomerAndContactLimits(objDealHeader.DealType, totalPayments, props.sellCurrencyID, objDealHeader.CustomerID, Number(objDealHeader.CustomerContactID));
    let alertTitle = '';
    let alertMessage = '';

    if (responseLimit.httpStatusCode === 200) {
      if (responseLimit.ValidationMessage === '') {
        callSaveDealHeader(objDealHeader);
      }
      else {
        if (responseLimit.LimitValidationStatus.toLowerCase() === 'warning') {
          setConfirmationModalTitle(getResource('Title.Warning'));
          setConfirmationModalMessage(responseLimit.ValidationMessage);
          setShowConfirmModalValidation(true);
        }
        else {
          alertTitle = getResource('Js_MessageError_TitleError');
          alertMessage = responseLimit.ValidationMessage;
          showModal(alertTitle, alertMessage);
          hideLoadingModal();
        }
      }
    }
    else {
      alertTitle = getResource('Js_MessageError_TitleError');
      alertMessage = getResource('Js_Message_TryAgainOrContactAdministrator');
      showModal(alertTitle, alertMessage);
      hideLoadingModal();
    }

    //callSaveDealHeader(objDealHeader);
  }

  const saveDealInProgress = async (status: string, jsonProperties: string) => {

    showLoadingModal();
    //Status
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    let dealStatus = userSession.DealApproveRequired ? 'Pending for Approval' : 'Accepted';
    if (status === 'Work in Progress') {
      dealStatus = status;
    }

    let outgoingFunds: any[] = [];

    disburses.forEach((disbursement: Disbursement, index: number) => {
      let outgoing = {
        DealHeaderID: props.dealHeaderID != null ? props.dealHeaderID : 0,
        ValueDate: formatDate(props.valueDate),
        UpdateToken: disbursement.beneficiary.UpdateToken != null ? disbursement.beneficiary.UpdateToken : 0,
        BeneficiaryID: disbursement.beneficiary.BeneficiaryID,
        BeneficiaryBankAccountID: disbursement.beneficiary.BankAccountID,
        BeneficiaryType: disbursement.beneficiary.BeneficiaryType,
        Equivalent: lockedSide === 'sell' ? formatToCurrency(disbursement.otherAmount.toString(), undefined, sellScale) : formatToCurrency(disbursement.amount.toString(), undefined, sellScale),
        EntityProperties: disbursement.entityProperties,
        EntityPropertiesJson: JSON.stringify(disbursement.entityProperties),
        OutgoingFundsID: disbursement.beneficiary.OutgoingFundsID != null ? disbursement.beneficiary.OutgoingFundsID : 0,
        PaymentAmount: lockedSide === 'sell' ? formatToCurrency(disbursement.amount.toString(), undefined, sellScale) : formatToCurrency(disbursement.otherAmount.toString(), undefined, sellScale),
        PaymentInstrument: disbursement.instrument,
        PaymentReference: disbursement.reference,
        ShowProperties: true,
        Status: 'Pending',
        WireItemFee: formatToCurrency(disbursement.fee.toString(), undefined, sellScale),
        WireItemFeeCurrencyID: props.buyCurrencyID,
      }
      outgoingFunds.push(outgoing);
    });

    /*
      details: Array(3)
      0: {label: "Currency", description: "AUD"}
      1: {label: "Instrument", description: "Electronic Funds Transfer"}
      2: {label: "Delivery Method", description: "Wire Transfer"}
    */
    let auxDetailsSettlementMehotd = props.settlementMehotd.details;

    let actualInstrument = '';
    let actualDeliveryMethod = '';

    if (auxDetailsSettlementMehotd != null) {
      auxDetailsSettlementMehotd.forEach((element: any) => {
        switch (element.label) {
          case 'Instrument': {
            actualInstrument = element.description;
            break
          }
          case 'Delivery Method': {
            actualDeliveryMethod = element.translationlabel//element.description;
            break
          }
        }
      });
    } else if (incomingFunds != null) {
      incomingFunds.forEach((element: any) => {
        actualInstrument = element.SettlementInstrument;
        actualDeliveryMethod = element.SettlementDeliveryMethod;
      });
    }

    const totalPayments = sumPayments();
    const finalAmount = totalPayments.toFixed(sellScale);
    const finalOtherAmount = amountCalc.toFixed(sellScale);

    let objDealHeader: any = {
      BuyAmount: (lockedSide === 'sell') ? finalOtherAmount : finalAmount,
      BuyCurrency: (props.fromTemplate !== undefined ? props.fromTemplate.SellCurrency : props.sellCurrencyID),
      CoverCustomerID: coverCustomerID,
      CustomerContactID: JSON.parse(localStorage.getItem('UserSession')!).ContactID,
      CreatedBy: localStorage.getItem('UserID'),
      CustomerID: JSON.parse(localStorage.getItem('UserSession')!).CustomerID,
      CustomerRate: customerRate,
      CustomerRefNo: '',
      ExternalInterface: externalInterface,
      ExternalInterfaceType: externalInterfaceType,
      DealHeaderID: props.dealHeaderID != null ? props.dealHeaderID : 0,
      LockedSide: lockedSide === 'sell' ? 'Sell' : 'Buy',
      MarketRate: marketRate,
      OutgoingFunds: outgoingFunds,
      ProfitAmount: profit,
      RateQuoteID: rateQuoteID,
      SellAmount: lockedSide === 'sell' ? finalAmount : finalOtherAmount,
      SellCurrency: (props.fromTemplate !== undefined ? props.fromTemplate.BuyCurrency : props.buyCurrencyID),
      Status: dealStatus,
      DealOrigin: "Online Deal",
      DealType: 1,
      UpdateBuyAmountField: lockedSide === 'sell' ? true : false,
      UpdatedBy: localStorage.getItem('UserID'),
      UpdateToken: updateToken,
      ValueDate: formatDate(props.valueDate),
      SettlementDeliveryMethods: actualDeliveryMethod,
      JsonProperties: jsonProperties,
      IncomingFunds: [{
        IncomingFundsID: incomingFunds[0] != null ? incomingFunds[0].IncomingFundsID : 0,
        Amount: lockedSide === 'sell' ? finalOtherAmount : finalAmount,
        BankAccountID: 0,
        SettlementInstrument: actualInstrument,
        SettlementDeliveryMethod: actualDeliveryMethod
      }]
    }

    setObjDealHeaderAfterValidation(objDealHeader);

    let responseLimit = await validateCustomerAndContactLimits(objDealHeader.DealType, totalPayments, props.sellCurrencyID, objDealHeader.CustomerID, Number(objDealHeader.CustomerContactID));
    let alertTitle = '';
    let alertMessage = '';

    if (responseLimit.httpStatusCode === 200) {
      if (responseLimit.ValidationMessage === '') {
        callSaveDealHeader(objDealHeader);
      }
      else {
        if (responseLimit.LimitValidationStatus.toLowerCase() === 'warning') {
          setConfirmationModalTitle(getResource('Title.Warning'));
          setConfirmationModalMessage(responseLimit.ValidationMessage);
          setShowConfirmModalValidation(true);
        }
        else {
          alertTitle = getResource('Js_MessageError_TitleError');
          alertMessage = responseLimit.ValidationMessage;
          showModal(alertTitle, alertMessage);
          hideLoadingModal();
        }
      }
    }
    else {
      alertTitle = getResource('Js_MessageError_TitleError');
      alertMessage = getResource('Js_Message_TryAgainOrContactAdministrator');
      showModal(alertTitle, alertMessage);
      hideLoadingModal();
    }

    //callSaveDealHeader(objDealHeader);
  }

  const callSaveDealHeader = (objDealHeader: any) => {
    saveDealHeader(objDealHeader).then(
      (responseSaveDealHeader) => {
        /*
          {
            "ApprovalsJson":null,
            "DealCreditStatus":"Automatically Approved",
            "DealHeaderID":14977,
            "ExternalInterfaceTradeID":null,
            "ExternalInterfaceTradeNumber":null,
            "Status":"Accepted",
            "HeldFundBalance":-1.0,
            "MessagePending":""
          }
        */
        let alertTitle = getResource('Js_MessageError_TitleError');
        let alertMessage = getResource('Message_DealError');

        if (responseSaveDealHeader !== undefined) {
          switch (Number(responseSaveDealHeader.httpStatusCode)) {
            case 200: {
              let showAlert = true;
              let userRequiredForAcceptance = false;
              let auxApprovalOptions: ApprovalOption = {
                Approvals: '',
                DealHeaderID: 0
              };
              alertTitle = getResource('Js_MessageError_TitleSuccess');
              switch (responseSaveDealHeader.Status) {
                case 'Accepted': {
                  alertMessage = getResource('Message_OrderCommitted').replace('DealHeaderID', responseSaveDealHeader.DealHeaderID);

                  if (responseSaveDealHeader.HeldFundBalance != null && responseSaveDealHeader.HeldFundBalance != undefined && responseSaveDealHeader.HeldFundBalance > -1) {
                    alertMessage = alertMessage + " " + getResource('YourCurrentBalance') + " " + formatToCurrency(responseSaveDealHeader.HeldFundBalance, undefined, sellScale) + ' ' + props.sellCurrencyID;
                  }
                  if (responseSaveDealHeader.LimitValidationMessage != undefined && responseSaveDealHeader.LimitValidationMessage != undefined) {
                    alertMessage = alertMessage + " , " + responseSaveDealHeader.LimitValidationMessage;
                  }
                  //this.sendConfirmationDirect(responseSaveDealHeader.DealHeaderID);
                  break;
                }
                case 'Pending for Approval': {
                  alertMessage = getResource('Message_OrderCommitted').replace('DealHeaderID', responseSaveDealHeader.DealHeaderID);
                  /*
                    {
                      "ApprovalsJson":"{\
                        "ContactAbleToApprove\":[
                          {
                            \"Name\":\"Francisco Duclos\",
                            \"Email\":\"pancho.duclos@gmail.com\",
                            \"MobilePhone\":\"\"
                          }
                        ]
                      }",
                      "DealCreditStatus":"Automatically Approved",
                      "DealHeaderID":15696,
                      "ExternalInterfaceTradeID":null,
                      "ExternalInterfaceTradeNumber":null,
                      "Status":"Pending for Approval",
                      "HeldFundBalance":-1.0,
                      "MessagePending":""
                    }
                  */
                  let approvals = responseSaveDealHeader.ApprovalsJson;
                  if (approvals === undefined) {
                    approvals = '';
                  }
                  if (approvals === '') {
                    alertTitle = getResource('Js_MessageError_TitleError');
                    alertMessage = getResource('Message_DealMissingApprovals');
                  } else {
                    showAlert = false;
                    auxApprovalOptions = {
                      Approvals: approvals,
                      DealHeaderID: responseSaveDealHeader.DealHeaderID
                    };
                  }
                  break;
                }
                case 'Pending':
                  alertMessage = getResource('Message_OrderSavedAsPending').replace('DealHeaderID', responseSaveDealHeader.DealHeaderID);

                  let complianceRulesFound = responseSaveDealHeader != undefined &&
                    ((responseSaveDealHeader.ComplianceSoftRules !== undefined && responseSaveDealHeader.ComplianceSoftRules !== null
                      && Array.isArray(responseSaveDealHeader.ComplianceSoftRules) && responseSaveDealHeader.ComplianceSoftRules.length > 0)
                      ||
                      (
                        responseSaveDealHeader.ComplianceRules !== undefined && responseSaveDealHeader.ComplianceRules !== null
                        && Array.isArray(responseSaveDealHeader.ComplianceRules) && responseSaveDealHeader.ComplianceRules.length > 0)
                    )

                  if (complianceRulesFound) {
                    alertMessage = getResource('Message_DealCompliance');
                  } else {
                    alertMessage = alertMessage + responseSaveDealHeader.MessagePending;
                  }

                  showModal(alertTitle, alertMessage);
                  //TODO: onNewTradeClick()

                  break;
                default: {
                  alertMessage = getResource('Message_OrderSaved').replace('DealHeaderID', responseSaveDealHeader.DealHeaderID);
                  break;
                }
              }
              hideLoadingModal();
              if (!userRequiredForAcceptance) {
                showModal(alertTitle, alertMessage);
                props.onFinish(responseSaveDealHeader.DealHeaderID, objDealHeader.Status === 'Work in Progress' ? true : false, auxApprovalOptions);
              }
              break;
            }
            default: {
              if (responseSaveDealHeader.ValidationMessage !== undefined) {
                //validation message from API
                alertTitle = getResource('Js_MessageError_TitleValidation');
                alertMessage = responseSaveDealHeader.ValidationMessage;
              }
              else {
                if (responseSaveDealHeader.httpErrorMessage !== undefined) {
                  //message from networking based on status code
                  alertMessage = responseSaveDealHeader.httpErrorMessage;
                }
              }

              if (responseSaveDealHeader.Message == 'SentToInterface') {
                alertMessage = getResource('Message_DealErrorSentInterface');
              }
              console.log("saveDeal");
              setQuoted(false);
              hideLoadingModal();
              showModal(alertTitle, alertMessage);
              break;
            }
          }
        } else {
          hideLoadingModal();
          showModal(alertTitle, alertMessage);
        }
      }
    );
  }

  const calculateFees = (actualCustomerRate: number, rateTerms: string, customerRate: number) => {
    let auxDetailsSettlementMehotd = props.settlementMehotd.details;

    let actualInstrument = '';
    let actualDeliveryMethod = '';

    if (auxDetailsSettlementMehotd != null) {
      auxDetailsSettlementMehotd.forEach((element: any) => {
        switch (element.label) {
          case 'Instrument': {
            actualInstrument = element.description;
            break
          }
          case 'Delivery Method': {
            actualDeliveryMethod = element.translationlabel//element.description;
            break
          }
        }
      });
    } else if (incomingFunds != null) {
      incomingFunds.forEach((element: any) => {
        actualInstrument = element.SettlementInstrument;
        actualDeliveryMethod = element.SettlementDeliveryMethod;
      });
    }

    const newDisburses = [...disburses];

    newDisburses.forEach(dis => {
      let newAmount = 0.00;

      if (rateTerms === 'I') {
        if (lockedSide === 'sell') {
          newAmount = dis.amount / customerRate;
        } else {
          newAmount = dis.amount * customerRate;
        }
      } else {
        if (lockedSide === 'sell') {
          newAmount = dis.amount * customerRate;
        } else {
          newAmount = dis.amount / customerRate;
        }
      }

      dis.otherAmount = Number(newAmount.toFixed(sellScale));
      setDisburses(newDisburses);
      forceUpdate();

      calculateWireItemFee(JSON.parse(localStorage.getItem('UserSession')!).CustomerID, props.sellCurrencyID, props.buyCurrencyID, dis.amount,
        actualCustomerRate, actualInstrument, actualDeliveryMethod).then(
          (responseSaveDealHeader) => {
            console.log("responseSaveDealHeader -> ", responseSaveDealHeader);
            /*
              FeeAmount: 0
              httpStatusCode: 200
            */
            if (responseSaveDealHeader.httpStatusCode === 200) {
              const newDisburses2 = [...disburses];
              let newFees = 0.00;
              newDisburses2.forEach(dis2 => {
                if (dis.key === dis2.key) {
                  dis2.fee = responseSaveDealHeader.FeeAmount;
                }
                newFees = newFees + dis2.fee;
              });
              setDisburses(newDisburses2);
              setFees(newFees);
            }
          }
        );
    });
  }

  //cuando se de al onchange de cada property //////////////////////////////////////////////////////////////////////////////////
  const dropdownPropertyChange = (item: any, name: string, index: number, disId: number) => {
    const auxDisburses = [...disburses];
    auxDisburses.find((dis) => dis.key === disId)!.entityProperties[index].TextValue = item.value;
    onUpdateOrderDisbursement(disId, 'EntityProperties', '', auxDisburses.find((dis) => dis.key === disId)!.entityProperties);;
  };

  const textPropertyChange = (event: any, index: number, disId: number) => {
    const auxDisburses = [...disburses];
    let currentDisKey = auxDisburses.find((dis) => dis.key === disId)!;
    currentDisKey!.entityProperties[index].TextValue = event.target.value;
    auxDisburses.forEach((auxDis: Disbursement) => {
      if (auxDis.key === disId) {
        auxDis = currentDisKey;
      }
    });
    //auxDisburses.find((dis) => dis.key === disId)!.entityProperties[index].TextValue = event.target.value;
    onUpdateOrderDisbursement(disId, 'EntityProperties', '', currentDisKey.entityProperties);;
  }

  const numberPropertyChange = (event: any, index: number, disId: number) => {
    const auxDisburses = [...disburses];
    auxDisburses.find((dis) => dis.key === disId)!.entityProperties[index].NumValue = Number(event.target.value);
    onUpdateOrderDisbursement(disId, 'EntityProperties', '', auxDisburses.find((dis) => dis.key === disId)!.entityProperties);;
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const findPropertieByDisbProperty = (properties: any[], id: number) => {
    let propertyFound: any = null;
    properties.forEach(prop => {
      if (prop['ID'] === id) {
        propertyFound = prop;
      }
    });
    return propertyFound;
  }

  const renderProperties = (properties: any[], propertiesByDisbursement: any[], disbursementID: number) => {
  /*
    crear los rows desde los properties que ya tengo creados
    busco el property inicial con el UserDefinedPropertyID en la lista de addProperties
    cuando ay tenga ese
    recien crear el row
  */
    let rows = [] as any[];

    propertiesByDisbursement.forEach((propertyDis, indexPropDis) => {
      let propertyElement = findPropertieByDisbProperty(properties, propertyDis.UserDefinedPropertyID);
      var propertyControl;

      if (propertyElement != null) {
        if (propertyElement['PossibleValues$'] != null) {
        //console.log(propertyElement['PossibleValues$']);
        propertyElement['PossibleValues$'].forEach((element: any) => {
          element.value = element.Value;
          element.label = element.Description;
        });

        propertyControl = (
          <Dropdown
            id={propertyElement.Description}
            title={propertyElement['PossibleValues$'][0].Description}
            onChange={(item: any, name: string) => {
              dropdownPropertyChange(item, name, indexPropDis, disbursementID);
            }}
            name={propertyElement.Description}
            list={propertyElement['PossibleValues$']}
            disabled={quoted}
          />
        );
      }
      else {

        switch (propertyElement['Data Type']) {
          case 'Text':
          default:
            propertyControl = (
              <input disabled={quoted} type="text" onChange={(event) => {
                textPropertyChange(event, indexPropDis, disbursementID);
              }} />
            );
            break;
          case 'Numeric':
            propertyControl = (
              <input type="number" disabled={quoted} onChange={(event) => {
                numberPropertyChange(event, indexPropDis, disbursementID);
              }} />
            );
            break;
          case 'DateTime':
            propertyControl = (
              <DatePicker />
            );
            break;
        }


      }
      //let randomNumber = Math.random() * 100000;
      rows.push(
        <div className="row">
          <div className="col-md-4 col-xs-12 row">
            <div className="row">
              <span>{propertyElement.Description}</span>
            </div>
          </div>
          <div className="col-md-5 col-xs-12">
            <div className="row">
              {propertyControl}
            </div>
          </div>
          <div className="col-md-1 col-xs-12 row middle-xs">
            <PaperClipOutlined
              onClick={() => {
                let objCurrentAtt = { id: propertyElement['ID'], description: propertyElement.Description, currentDisbursementID: disbursementID };
                setCurrentAttachmentProp(objCurrentAtt);
                setShowAttachmentForm(true);
              }}
            />
          </div>
        </div>
      );
      }
    });
    return rows;
  }

  const showAdditionalProperties = (idRow: number) => {
    const auxShowAddProp = [...showAddProp];
    let showOrNot: any[] = auxShowAddProp.filter(addProp => addProp.id === idRow);
    if (showOrNot[0]) {
      return showOrNot[0].show ? showOrNot[0].rows : [];
    } else {
      return [];
    }
  }

  const onClickMore = (idRow: number) => {
    if (!quoted) {
      /*const auxShowAddProp = [...showAddProp];
      auxShowAddProp.forEach(element => {
        if (element.id == idRow) {
          element.show = !element.show;
        }
      });
      setShowAddProp(auxShowAddProp);*/
      const auxDis = [...disburses];
      auxDis.forEach(dis => {
        if (dis.key === idRow) {
          dis.showProperties = !dis.showProperties;
        }
      });
      setDisburses(auxDis);
    }
  }

  const resetOthersAmounts = () => {
    let auxDisburses = [...disburses];
    auxDisburses.forEach(element => {
      element.otherAmount = NaN;
    });
    setDisburses(auxDisburses);
  }

  const sumPayments = () => {
    let sum = 0;
    disburses.forEach(dis => {
      sum = sum + (isNaN(dis.amount) ? 0 : dis.amount);
    });
    return sum;
  }

  const getAttachmentsByEntityId = () => {
    let arrayAttach: any[] = [];

    const auxDisburses = [...disburses];
    auxDisburses.forEach(dis => {
      if (dis.key === currentAttachmentProp.currentDisbursementID) {
        dis.entityProperties.forEach(entpro => {
          if (entpro.UserDefinedPropertyID === currentAttachmentProp.id) {
            arrayAttach = entpro.Attachments;
          }
        });
      }
    });

    return arrayAttach;
  }

  const closeConfirmationModal = () => {
    setShowConfirmModal(false)
    setDealToConfirmAcceptance({})
    setConfirmationModalTitle('')
    props.onBack();
  }

  const acceptDealInConfirmationModal = () => {
    setShowConfirmModal(false)
    console.log(dealToConfirmAcceptance)
    showLoadingModal();
    callSaveDealHeader(dealToConfirmAcceptance);
  }

  const onQuoteClick = (rate: number, ratePrecision: number, newAmount: number) => {

    let line1 = getResource('OrderConfirmation_Message_Summary');
    let line2 = getResource('OrderConfirmation_Message_Payments');
    let line3 = getResource('OrderConfirmation_Message_Confirm');
    let buyCcy = lockedSide === 'sell' ? props.buyCurrencyID : props.sellCurrencyID;
    let sellCcy = lockedSide !== 'sell' ? props.buyCurrencyID : props.sellCurrencyID;
    let buyAmount = formatToCurrency((sumPayments()).toString(), props.buyCurrencyID, buyScale) + " " + buyCcy;
    let sellAmount = formatToCurrency((newAmount).toString(), props.sellCurrencyID, sellScale) + " " + sellCcy;
    let rateSummary = formatToCurrency(rate.toString(), undefined, ratePrecision);

    let payments = disburses.length;

    line1 = line1.replace('{BuyAmount}', buyAmount).replace('{SellAmount}', sellAmount).replace('{Rate}', rateSummary);
    line2 = line2.replace('{Payments}', payments).replace('{Total}', buyAmount);

    let message = <div>
      <p>{line1}</p>
      <p>{line2}</p>
      <p>{line3}</p>
    </div>;
    setConfirmationMessage(message);
  }

  const yesLimitValidationAlert = () => {

    let objDealHeader = objDealHeaderAfterValidation;

    setShowConfirmModalValidation(false);
    callSaveDealHeader(objDealHeader);
  }

  const closeLimitValidationAlert = () => {
    hideLoadingModal();
    setShowConfirmModalValidation(false);
    setQuoted(false);
    setSubmit(false);
  }

  const getInstrumentsByRow = (row: number) => {

    let newInstruments: any = instrumentsForNonAccounts as [];
    if (Number(disburses[row].beneficiary.BankAccountID) > 0) {
      newInstruments = instruments as [];
    }

    let listInstruments: any = [];

    if (disburses[row].beneficiary.BeneficiaryID > 0) {
      listInstruments = newInstruments.filter((i: any) => i.value !== "Hold fund in Client's account");
    }
    else {
      if (disburses[row].beneficiary.BeneficiaryID === 0 && allowHoldFundsInstrument) {
        listInstruments.push({ label: "Hold fund in Client's account", value: "Hold fund in Client's account" });
      }
      else {
        listInstruments = newInstruments;
      }
    }

    return listInstruments;
  }

  return (
    <>
      <div className="row center-xs">
        <div className="col-md-12">
          <div className="row center-xs">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 col-xs-12 row middel-xs align-center">
                  <div className="col-md-12 col-xs-12 row start-xs">
                    <span className="subtitle-text">{getResource('Label.Totals')}</span>
                  </div>
                  <div className="col-md-1 col-xs-10 row start-xs"></div>
                  <div className="col-md-11 col-xs-10 row start-xs">
                    <div className="col-md-12 col-xs-12 row start-xs">
                      <span className="bold-text-med">
                        {getResource('Js_Action_Payments')}: {formatToCurrency((sumPayments()).toString(), undefined, buyScale)}
                      </span>
                    </div>
                    <div className="col-md-12 col-xs-12 row start-xs">
                      <span className="bold-text-med">
                        {getResource('Table_TH_Settlement')}: {formatToCurrency((amountCalc).toString(), undefined, sellScale)}
                      </span>
                    </div>
                    <div className="col-md-12 col-xs-12 row">
                      <span className="bold-text-med">
                        {getResource('Label.Fees')}: {formatToCurrency((fees).toString(), undefined, sellScale)}
                      </span>
                    </div>
                    <div className="col-md-4 col-xs-12 row">
                      <span className="bold-text-med">{getResource('Label.NumberLines')}: {disburses.length}</span>
                    </div>
                    <div className="col-md-8 col-xs-12 row start-xs" style={{ top: -5, position: 'relative' }}>
                      <Radio.Group
                        onChange={(event) => {
                          setLockedSide(event.target.value);
                          setamountCalc(0);
                          resetOthersAmounts();
                        }}
                        value={lockedSide}
                        buttonStyle="solid"
                        size="middle"
                        disabled={quoted}
                      >
                        <Radio.Button value={'sell'} >{getResource('Form.Label.LockBuySide')}</Radio.Button>
                        <Radio.Button value={'buy'} >{getResource('Form.Label.LockSellSide')}</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12 row middle-xs center-xs">

                  {/*<Button type="primary" onClick={() => { }}>Quote</Button>*/}
                  { }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Divider style={{ marginTop: 0 }} />
            <div className="row middle-xs end-xs">
              <div className="col-md-3 col-xs-12 row middle-xs end-xs">
                {(currencyGroup != 'Exotic' || (currencyGroup == 'Exotic' && disburses.length == 0)) && <Button type={quoted ? 'disable' : 'secondary'} onClick={() => {
                  addARowToList(addProperties as any[], null, true);
                }}>
                  {getResource('Button.Add')}
                </Button>
                }
              </div>
            </div>
            <div className="col-md-3 col-xs-12 header-hidden">
              <span>{getResource('Js_TH_Beneficiary')}</span>
            </div>
            <div className="col-md-3 col-xs-12 header-hidden">
              <span>{getResource('Label.Instrument')}</span>
            </div>
            <div className="col-md-1°5 col-xs-12 header-hidden">
              <span>{getResource('Label.WeSend')} ({props.fromTemplate !== undefined ? props.fromTemplate.BuyCurrency : props.buyCurrencyID})</span>
            </div>
            <div className="col-md-1°5 col-xs-12 header-hidden">
              <span>{getResource('Label.WePay')}  ({/*props.fromTemplate !== undefined ? props.fromTemplate.SellCurrency : */props.sellCurrencyID})</span>
            </div>
            <div className="col-md-1 col-xs-12 header-hidden">
              <span>{getResource('Label.Fee')} ({props.sellCurrencyID})</span>
            </div>
            <div className="col-md-1 col-xs-12 header-hidden">
              <span>{getResource('Label.Reference')}</span>
            </div>
            <div className="col-md-1 col-xs-12 header-hidden">
              <span>{getResource('Table_TH_Actions')}</span>
            </div>
            {/* ----------- Lista ----------------------------- */}
            <div className="row">
              {disburses.map((r, i) => (
                <>
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <span className="label-visible">{getResource('Js_TH_Beneficiary')}</span>
                      <div className="row">
                        <Dropdown
                          className={submit && disburses[i].beneficiary.value === '' ? 'input-error' : ''}
                          id={"beneId" + disburses[i].key}
                          name="beneficiaryDropdown"
                          title={getResource('SelectBeneficiary')}
                          list={beneficiariesList}
                          onChange={(item: any) => { onUpdateOrderDisbursement(disburses[i].key, 'Beneficiary', item); }}
                          select={{ value: disburses[i].beneficiary.value }}
                          disabled={quoted}
                          /*submit={submit}
                          errorMessage={'Beneficiary is required!'}*/
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <span className="label-visible">{getResource('Label.Instrument')}</span>
                      <div className="row">
                        <Dropdown
                          className={submit && disburses[i].instrument === '' ? 'input-error' : ''}
                          id={"instrumentId" + disburses[i].key}
                          name="instrumentDropdown"
                          title={getResource('SelectInstrument')}
                          list={getInstrumentsByRow(i)}
                          onChange={(item: any) => { onUpdateOrderDisbursement(disburses[i].key, 'Instrument', item.value); }}
                          select={{ value: disburses[i].instrument }}
                          disabled={quoted || disburses[i].instrument === "Hold fund in Client's account"}
                          /*submit={submit}
                          errorMessage={'Instrument is required!'}*/
                        />
                      </div>
                    </div>
                    <div className="col-md-1°5 col-xs-12">
                      <span className="label-visible">{getResource('Label.WeSend')}</span>
                      <div className="row">
                        <Input
                          className={lockedSide === 'sell' && submit ? (disburses[i].amount === 0 || isNaN(disburses[i].amount) ? 'input-error' : '') : ''}
                          type="number2"
                          disabled={lockedSide === 'buy' || quoted}
                          value={lockedSide === 'sell' ? disburses[i].amount : disburses[i].otherAmount}
                          onValueChange={(value: number) => {
                            /*
                              floatValue: 55
                              formattedValue: "55"
                              value: "55"
                            */
                            if (!quoted && lockedSide === 'sell') {
                              onUpdateOrderDisbursement(disburses[i].key, 'Amount', value);
                            }
                          }}
                          /*submit={(lockedSide === 'buy' || quoted) ? false : submit}
                          errorMessage={'Amount is required!'}*/
                          decimalScale={buyScale}
                        />
                      </div>
                    </div>
                    <div className="col-md-1°5 col-xs-12">
                      <span className="label-visible">{getResource('Label.WePay')}</span>
                      <div className="row">
                        <Input
                          className={lockedSide === 'buy' && submit ? (disburses[i].amount === 0 || isNaN(disburses[i].amount) ? 'input-error' : '') : ''}
                          type="number2"
                          disabled={lockedSide === 'sell' || quoted}
                          value={lockedSide === 'buy' ? disburses[i].amount : disburses[i].otherAmount}
                          onValueChange={(value: number) => {
                            if (!quoted && lockedSide === 'buy') {
                              onUpdateOrderDisbursement(disburses[i].key, 'Amount', value);
                            }
                          }}
                          decimalScale={sellScale}
                          /*submit={(lockedSide === 'sell' || quoted ? false : submit}
                          errorMessage={'Amount is required!'}*/
                        />
                      </div>
                    </div>
                    <div className="col-md-1 col-xs-12">
                      <span className="label-visible">{getResource('Label.Fee')}</span>
                      <div className="row">
                        <Input type="text" disabled={true} value={disburses[i].fee.toFixed(sellScale)} />
                      </div>
                    </div>
                    <div className="col-md-1 col-xs-12">
                      <span className="label-visible">{getResource('Label.Reference')}</span>
                      <div className="row">
                        <input type="text" disabled={quoted} value={disburses[i].reference} onChange={(event) => { onUpdateOrderDisbursement(disburses[i].key, 'Reference', event.target.value); }} />
                      </div>
                    </div>
                    <div className="col-md-1 col-xs-12 center" >

                      <DeleteTwoTone style={{ marginRight: '20px', marginTop: '10px' }} onClick={() => { deleteADisbursementRow(disburses[i].key); }} twoToneColor={"#34c521"} />
                      <PlusCircleTwoTone style={{ marginTop: '10px' }} onClick={() => { onClickMore(disburses[i].key) }} twoToneColor={"#34c521"} />

                    </div>
                  </div>
                  <div className="row">
                    {/*<>{showAdditionalProperties(disburses[i].key)}</>*/}
                    {r.showProperties && disburses[i].entityProperties.map(function (entity: any, index: number) {

                      /*
                        AmountLimit: 200
                        Attachments: []
                        AttachmentsRequired: false
                        DataType: "Text"
                        DateValue: null
                        Description: "Copy of invoice and number"
                        EntityPropertyID: null
                        IsValid: true
                        LookupTableAttachmentsRequired: false
                        Mandatory: true
                        NumValue: null
                        TextValue: ""
                        UserDefinedPropertyID: 3181
                      */

                      let propertyElement = findPropertieByDisbProperty(addProperties as any[], entity.UserDefinedPropertyID);
                      /*
                        Data Type: "Text"
                        Description: "Copy of invoice and number"
                        Display Order: 10
                        ExtAmountLimit$: 200
                        ExtAttachmentsRequired$: false
                        ExtMandatory$: true
                        ExtUpdateToken$: "637649787715866667"
                        ExtUserPropertyID$: 3181
                        Group By: null
                        ID: 3181
                        PossibleValues$: null
                        Short Description: "Invoice"
                      */
                      let propertyControl;
                      if (propertyElement != null) {
                      if (propertyElement['PossibleValues$']) {
                        propertyElement['PossibleValues$'].forEach((element: any) => {
                          element.value = element.Value;
                          element.label = element.Description;
                        });

                        propertyControl = (
                          <Dropdown
                            className={submit && !validateEntity(r, entity) ? 'input-error' : ''}
                            id={propertyElement.Description}
                            title={propertyElement['PossibleValues$'][0].Description}
                            select={{ value: entity.TextValue }}
                            onChange={(item: any, name: string) => {
                              dropdownPropertyChange(item, name, index, r.key);
                            }}
                            name={propertyElement.Description}
                            list={propertyElement['PossibleValues$']}
                            disabled={quoted}
                          />
                        );
                      }
                      else {

                        switch (propertyElement['Data Type']) {
                          case 'Text':
                          default:
                            propertyControl = (
                              <input
                                className={submit && !validateEntity(r, entity) ? 'input-error' : ''}
                                disabled={quoted}
                                type="text"
                                value={entity.TextValue}
                                onChange={(event) => {
                                  textPropertyChange(event, index, r.key);
                                }}
                              />
                            );
                            break;
                          case 'Numeric':
                            propertyControl = (
                              <input
                                className={submit && !validateEntity(r, entity) ? 'input-error' : ''}
                                type="number"
                                disabled={quoted}
                                value={entity.NumValue}
                                onChange={(event) => {
                                  numberPropertyChange(event, index, r.key);
                                }}
                              />
                            );
                            break;
                          case 'DateTime':
                            propertyControl = (
                              <DatePicker />
                            );
                            break;
                        }
                      }

                      return (
                        <div className="row">
                          <div className="col-md-4 col-xs-12 row">
                            <div className="row">
                              <span>{entity.Description}</span>
                            </div>
                          </div>
                          <div className="col-md-5 col-xs-12">
                            <div className="row">
                              {propertyControl}
                            </div>
                          </div>
                          <div className="col-md-1 col-xs-12 row middle-xs">
                            <PaperClipOutlined
                              onClick={() => {
                                let objCurrentAtt = { id: propertyElement['ID'], description: propertyElement.Description, currentDisbursementID: r.key };
                                setCurrentAttachmentProp(objCurrentAtt);
                                setShowAttachmentForm(true);
                              }}
                            />
                          </div>
                        </div>
                      );
                      }
                    })}
                  </div>
                  <Divider className='label-visible' style={{ marginBottom: '10px' }} />
                </>
              ))}
            </div>

            {/* ----------------------------------------------- */}
            <Divider style={{ marginTop: 0 }} />
          </div>
          <div className="row-actions">

            <Button type={quoted ? "disable" : "secondary"} onClick={() => { props.onBack(); }}>{getResource('Btn_Back')}</Button>
            <Button type={valdiatePrevRows(false, false)[0] && !quoted ? "primary" : 'disable'} onClick={() => {
                setShowAddLikeTemplateModal(true);
            }}>{getResource('Button.SaveTemplate')}</Button>
            <Button type={'disable'} onClick={() => { voidOrder(); }}>{getResource('Btn_Void')}</Button>
            <Button type={quoted ? "primary" : "disable"} onClick={() => { saveDealInProgress('Work in Progress', ''); }}>{getResource('Btn_WorkInProgress')}</Button>

            <QuoteBox
              type={'order'}
              mode={'modal'}
              dealType={1}
              buyCurrency={props.fromTemplate !== undefined ? props.fromTemplate.BuyCurrency : props.buyCurrencyID}
              sellCurrency={props.fromTemplate !== undefined ? props.fromTemplate.SellCurrency : props.sellCurrencyID}
              customerID={Number.parseInt(JSON.parse(localStorage.getItem('UserSession')!).CustomerID)}
              contactID={Number.parseInt(JSON.parse(localStorage.getItem('UserSession')!).ContactID)}
              valueDate={props.valueDate}
              forwardNearDate={props.valueDate}
              amount={amount}
              lockedSide={lockedSide}
              totalFeesAmount={0}
              amountCalc={(newamount: number) => { setamountCalc(newamount); }}
              atTheSameTimeToClickQuote={() => { setQuoted(true); }}
              onTimeNoEnd={(rateQuoteID: string, customerRate: number, marketRate: number, profit: number, coverCustomerID: number, externalInterface: string, externalInterfaceType: string, rateTerms: string) => {
                setRateQuoteID(rateQuoteID);
                setCustomerRate(customerRate);
                setMarketRate(marketRate);
                setProfit(profit);
                setCoverCustomerID(coverCustomerID);
                setExternalInterface(externalInterface);
                setExternalInterfaceType(externalInterfaceType);
                calculateFees(customerRate, rateTerms, customerRate);

                const auxShowAddProp = [...showAddProp];
                auxShowAddProp.forEach(element => {
                  element.show = false;
                });
                setShowAddProp(auxShowAddProp);
              }}
              onAcceptClick={(rateQuoteID: string, customerRate: number, marketRate: number, profit: number, coverCustomerID: number, externalInterface: string, externalInterfaceType: string, rateTerms: string, jsonProperties: string) => {
                saveDeal('', rateQuoteID, customerRate, marketRate, profit, coverCustomerID, externalInterface, externalInterfaceType, rateTerms, jsonProperties); setQuoted(false);
              }
              }
              onCancelQuote={() => { setQuoted(false); setSubmit(false); }}
              validateFields={() => {
                setSubmit(true);
                let [isvalid, lines] = valdiatePrevRows(false, true);
                console.log(isvalid)
                return isvalid;
              }}
              onError={() => {
                setQuoted(false); setSubmit(false);
              }}
              showConfirmation={true}
              confirmationMessage={confirmationMessage}
              confirmationTitle={getResource('OrderConfirmation_Title')}
              onQuoteClick={onQuoteClick}
            />

          </div>
        </div>
      </div>
      <AttachmentsForm
        key={'orders-disbursement-attachment-form'}
        id={'orders-disbursement-attachment-form'}
        show={{ id: currentAttachmentProp.description, show: showAttachmentForm }}
        onClose={() => { setShowAttachmentForm(false); }}
        useLinkID={false}
        onUploadWithoutLink={(attachmentID, fileName, description) => {
          //console.log('--attachmentID -> ' + { attachmentID } + '--fileName' + fileName + '--description' + description);

          let objAttach: any = {
            ID: attachmentID,
            FileName: fileName,
            Description: description,
          }
          const auxDisburses = [...disburses];
          auxDisburses.forEach(dis => {
            if (dis.key === currentAttachmentProp.currentDisbursementID) {
              dis.entityProperties.forEach(entpro => {
                if (entpro.UserDefinedPropertyID === currentAttachmentProp.id) {
                  entpro.Attachments.push(objAttach);
                }
              });
            }
          });
          setDisburses(auxDisburses);
        }}
        showList={true}
        attachmentsList={getAttachmentsByEntityId()}
      />
      <AddEditOrderTemplate
        type={'add'}
        show={showAddLikeTemplateModal}
        orderObj={{
          Type: props.fromTemplate !== undefined ? 'template' : 'new',
          TemplateObj: props.fromTemplate,
          Disbursements: disburses,
          BuyCurrency: props.fromTemplate !== undefined ? props.fromTemplate.BuyCurrency : props.buyCurrencyID,
          SellCurrency: props.fromTemplate !== undefined ? props.fromTemplate.SellCurrency : props.sellCurrencyID,
          Amount: amount
        }}
        fromOrdersDisbursement={true}
        saveTemplateModalProps={{
          ButtonLabel: getResource('Button.AddOrderTemplate'),
          Modaltitle: getResource('Button.NewOrder')
        }}
        onCancel={() => setShowAddLikeTemplateModal(false)}
      />
      <ConfirmationModal visible={showConfirmModal} cancel={closeConfirmationModal} confirm={acceptDealInConfirmationModal} title={confirmationModalTitle} />
      <ConfirmationModal visible={showConfirmModalValidation} cancel={closeLimitValidationAlert} confirm={yesLimitValidationAlert} mainTitle={confirmationModalTitle} title={confirmationModalMessage} />
    </>
  );
}

export default OrdersDisbursement;