export const OnlineRightsNames =
{
  BeneApproveRequired: 'BeneApproveRequired',
  DealApproveRequired: 'DealApproveRequired',
  OL_BeneficiaryAccess: 'OL_BeneficiaryAccess',
  OL_BeneficiaryAddNew: 'OL_BeneficiaryAddNew',
  OL_BeneficiaryApprove: 'OL_BeneficiaryApprove',
  OL_BeneficiaryEdit: 'OL_BeneficiaryEdit',
  OL_BeneficiaryImport: 'OL_BeneficiaryImport',
  OL_BeneficiaryUser: 'OL_BeneficiaryUser',
  OL_MSBRole: 'OL_MSBRole',
  OL_ComplianceUploadDocuments: 'OL_ComplianceUploadDocuments',
  OL_DealApprove: 'OL_DealApprove',
  OL_Disburse: 'OL_Disburse',
  OL_DrawdownAddNew: 'OL_DrawdownAddNew',
  OL_DrawdownApprove: 'OL_DrawdownApprove',
  OL_ForwardAccess: 'OL_ForwardAccess',
  OL_ForwardAddNew: 'OL_ForwardAddNew',
  OL_ForwardApprove: 'OL_ForwardApprove',
  OL_ForwardCreateClosed: 'OL_ForwardCreateClosed',
  OL_ForwardCreateOpen: 'OL_ForwardCreateOpen',
  OL_ForwardGetQuote: 'OL_ForwardGetQuote',
  OL_MyAccountInformation: 'OL_MyAccountInformation',
  OL_MyAccountInformation_Details: 'OL_MyAccountInformation_Details',
  OL_MyAccountInformation_Addresses: 'OL_MyAccountInformation_Addresses',
  OL_MyAccountInformation_MyBankAccounts: 'OL_MyAccountInformation_MyBankAccounts',
  OL_MyAccountInformation_Contacts: 'OL_MyAccountInformation_Contacts',
  OL_MyAccountInformation_Balances: 'OL_MyAccountInformation_Balances',
  OL_MyAccountInformation_BalancesHistory: 'OL_MyAccountInformation_BalancesHistory',
  OL_OrderAccess: 'OL_OrderAccess',
  OL_OrderAddNew: 'OL_OrderAddNew',
  OL_OrderApprove: 'OL_OrderApprove',
  OL_OrderImport: 'OL_OrderImport',
  OL_RatesForwardAccess: 'OL_RatesForwardAccess',
  OL_RatesForwardAddNew: 'OL_RatesForwardAddNew',
  OL_RatesForwardEdit: 'OL_RatesForwardEdit',
  OL_RatesSection: 'OL_RatesSection',
  OL_RatesSpotAccess: 'OL_RatesSpotAccess',
  OL_RatesSpotAddNew: 'OL_RatesSpotAddNew',
  OL_RatesSpotEdit: 'OL_RatesSpotEdit',
  OL_RegularPaymentAccess: 'OL_RegularPaymentAccess',
  OL_RegularPaymentEdit: 'OL_RegularPaymentEdit',
  OL_RegularPaymentAddNew: 'OL_RegularPaymentAddNew',
  OL_SpotAccess: 'OL_SpotAccess',
  OL_SpotAddNew: 'OL_SpotAddNew',
  OL_SpotApprove: 'OL_SpotApprove',
  OL_SpotGetQuote: 'OL_SpotGetQuote',
  OL_TemplateAccess: 'OL_TemplateAccess',
  OL_TransactionsHistory: 'OL_TransactionsHistory',
  OL_TransactionsHistoryAccess: 'OL_TransactionsHistoryAccess',
  OL_TransactionsBeneficiaryPayments: 'OL_TransactionsBeneficiaryPayments',
  OL_ToolsAccess: 'OL_ToolsAccess',
  OL_ToolsQB: 'OL_ToolsQB',
  PaymentOrderApproveRequired: 'PaymentOrderApproveRequired',
  OL_Invoicing: 'OL_Invoicing',
  OL_SendBrowserNotification: 'OL_SendBrowserNotification',
  OL_PayNow: 'OL_PayNow'
};