import { useEffect, useState } from 'react';
import { getUserDefinedPropertiesBySubEntityType } from '../NetworkingUserDefinedProperties';


const useUserDefinedProperties = (entityType: string) => {
  const [properties, setProperties] = useState<any[]>([]);
  const [needsReloading, setNeedsReloading] = useState(true);

  useEffect(() => {
    const loadProperties = async () => {
      const json = await getUserDefinedPropertiesBySubEntityType(entityType, 0, 0);
      var helper = [] as any[];
      if (json.properties) {
        json.properties.forEach((property: any) => {
          if (property['ExtUserPropertyID$'] || (entityType.toLowerCase().includes('outgoing') &&
            property.Description.toLowerCase().includes('invoice'))) {
            helper.push(property);
          }
        }) //ExtAttachmentsRequired Mandatory
      }
      setProperties(helper);
      setNeedsReloading(false);
    };
    if (needsReloading) {
      loadProperties();
    }
  }, [needsReloading]);

  return [properties, setNeedsReloading];
};

export default useUserDefinedProperties;