import { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { getCurrenciesByCustomer } from '../../../api/NetworkingCurrencies';
import { getOnlineDefaults, saveCustomerOnlineAccountDefaults } from '../../../api/NetworkingCustomers';
import useLookUpTables, { LookUpTableDetail } from '../../../api/hooks/useLookUpTables';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import Dropdown from '../../inputs/Dropdown';

const MyDefaults = () => {
  const { showModal } = useContext(AppContext);
  const [accountDefaults, setAccountDefaults] = useState([]);
  const [paymentCurrencies, setPaymentCurrencies] = useState([]);
  const [settlementCurrencies, setSettlementCurrencies] = useState([]);
  const initialPagesLookup = useLookUpTables('Initial Online Pages');
  const [initialPages, setInitialPages] = useState<LookUpTableDetail[]>();
  let OL_PayNow = useOnlineRights(OnlineRightsNames.OL_PayNow);

  useEffect(() => {

    const loadInitialPages = async () => {
      if (initialPagesLookup && initialPagesLookup.InitialOnlinePages.length > 0) {

        if (!OL_PayNow) {

          let pages = initialPagesLookup.InitialOnlinePages.filter((b: LookUpTableDetail) => b.value !== 'Beneficiary Pay');
          setInitialPages(pages);
        }
        else {
          setInitialPages(initialPagesLookup.InitialOnlinePages);
        }
      }
    }
    loadInitialPages();
  }, [initialPagesLookup, OL_PayNow]);

  useEffect(() => {
    const loadCustomerCurrencies = async () => {
      const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
      const json = await getCurrenciesByCustomer(customerID);
      if (json != null) {
        const paymentCurrencies = [{ Type: 'Payment', CurrencyID: '', CurrencyIDName: getResource('SelectCurrency') }]
          .concat(json.currencies.filter((c: any) => c.Type === 'Payment'))
          .map((c: any) => ({ ...c, label: c.CurrencyIDName, value: c.CurrencyID })) as [];
        const settlementCurrencies = [{ Type: 'Settlement', CurrencyID: '', CurrencyIDName: getResource('SelectCurrency') }]
          .concat(json.currencies.filter((c: any) => c.Type === 'Settlement'))
          .map((c: any) => ({ ...c, label: c.CurrencyIDName, value: c.CurrencyID })) as [];

        setPaymentCurrencies(paymentCurrencies);
        setSettlementCurrencies(settlementCurrencies);
      }
    };
    const loadAccountDefaults = async () => {
      const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
      const contactID = JSON.parse(localStorage.getItem('UserSession')!).ContactID;
      const json = await getOnlineDefaults(customerID, contactID);
      if (json != null && json.defaults != null) {
        let orderedDefaults = [...json.defaults];

        /*if (orderedDefaults != null && orderedDefaults.length > 1) {
          const temp = orderedDefaults[0];
          orderedDefaults[0] = orderedDefaults[1];
          orderedDefaults[1] = temp;
        }*/
        setAccountDefaults(orderedDefaults as []);
      }
    };
    loadCustomerCurrencies();
    loadAccountDefaults();
  }, []);

  const saveAccountDefaults = async () => {
    const userSession = JSON.parse(localStorage.getItem('UserSession')!);
    const json = await saveCustomerOnlineAccountDefaults(userSession.CustomerID, userSession.ContactID, accountDefaults);
    if (json != null) {
      switch (json.httpStatusCode) {
        case 200: {
            showModal(getResource('Js_MessageError_TitleSuccess'), 'Account defaults changed successfully.');

          updateUpdateTokens(json);
           /* updateUpdateTokens([
              { Name: 'DefaultBuyCurrencyID', Value: json.DefaultBuyCurrencyID },
              { Name: 'DefaultSellCurrencyID', Value: json.DefaultSellCurrencyID }
            ]);
          }*/

          break;
        }

        default: {
          showModal(getResource('Js_MessageError_TitleError'), json.Message);
          break;
        }
      }
    }
  };

  const updateUpdateTokens = (json: any[]) => {
    let data = Object.keys(json).map(function (key: any) {
      return [key, json[key]];
    });
    let newDefaults = [...accountDefaults] as any[];
    for (let i = 0; i < newDefaults.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (data[j][0] === newDefaults[i].PropertyDescription) {
          newDefaults[i].UpdateToken = data[j][1];
        }
      }
    }
    setAccountDefaults(newDefaults as []);
  };

  const onChangeDefault = (property: any, value: any) => {
    if (property != null && value !== '') {
      const defaults = accountDefaults.map((accountDefault: any) => {
        if (property.PropertyDescription === accountDefault.PropertyDescription) {
          return { ...accountDefault, NewValue: value.value };
        }
        return accountDefault;
      }) as [];
      setAccountDefaults(defaults);
    }
  };

  return (
    <div className="screen-container">
      {accountDefaults.map((property: any, index: number) => (
        <div className="row center-xs">
          <div className="col-md-5 col-xs-12">
            <div className="row">
              <span className="input-label">{property.Name /*.includes('Buy') ? getResource('Form.Label.SellCurrency') : getResource('Form.Label.BuyCurrency')*/}</span>
            </div>
            <div className="row">
              {property.Name.includes('Sell') ? (
                <Dropdown
                  key={'settlement-currencies-' + index}
                  name="settlement-currencies"
                  title="Select a currency..."
                  select={{ value: property.NewValue }}
                  list={settlementCurrencies}
                  onChange={(value: any, label: string) => onChangeDefault(property, value)}
                />
              ) : (property.Name.includes('Buy') ? (
                <Dropdown
                  key={'payment-currencies-' + index}
                  name="payment-currencies"
                  title="Select a currency..."
                  select={{ value: property.NewValue }}
                  list={paymentCurrencies}
                    onChange={(value: any, label: string) => onChangeDefault(property, value)}
                />
              ) :
                (<Dropdown
                  key={'initial-page' + index}
                  name="initial-page"
                  title="Select an initial page..."
                  select={{ value: property.NewValue }}
                  list={initialPages}
                  onChange={(value: any, label: string) => onChangeDefault(property, value)}
                />))
              }
            </div>
          </div>
        </div>
      ))}
      <div className="screen-container">
        <div className="row center-xs">
          <div className="col-md-5 col-xs-12">
            <Button onClick={saveAccountDefaults}>{getResource('Button.Save')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDefaults;
