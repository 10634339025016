import { DeleteTwoTone, PaperClipOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import { Badge, Collapse, DatePicker, Divider } from 'antd';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { getBeneficiaryForDisbursementsScreen } from '../../../api/NetworkingBeneficiaries';
import { getCurrenciesByCustomer } from '../../../api/NetworkingCurrencies';
import { getDealByID, getFullDealHeader } from '../../../api/NetworkingDeals';
import { calculateWireItemFee } from '../../../api/NetworkingFees';
import { saveOutgoingFunds } from '../../../api/NetworkingFunds';
import useCustomerCurrencies from '../../../api/hooks/useCustomerCurrencies';
import useInstruments from '../../../api/hooks/useInstruments';
import useUserDefinedProperties from '../../../api/hooks/useUserDefinedProperties';
import { formatIconClass, formatToCurrency, formatValidInputClass, validInputClass, validInputClassAllowZero } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import AttachmentsForm from '../../attachments/AttachmentsForm';
import Button from '../../inputs/Button';
import Dropdown from '../../inputs/Dropdown';
import DropdownFlag from '../../inputs/DropdownFlag';
import Input from '../../inputs/Input';

const { Panel } = Collapse;

interface PaymentDisburseProps {
  dealObject: any;
  sellCurrency: string;
  buyCurrency: string;
  amount: number;
  dealHeaderID: number;
  customerRate: number;
  valueDate: string;
  settlementInstrument: string;
  deliveryMethod: string;
  //baseCurrencyDirectRate: number;
  onSubmit: Function;
  onError?: (errorMessage: string) => void;
  onBack?: Function;
}

interface DisburseProps {
  disbursmentObject: any;
  sellCurrency: string;
  buyCurrency: string;
  customerRate: number;
  objectID: number;
  beneficiaries: any[];
  instrument: string;
  deliveryMethod: string;
  baseCurrencyDirectRate: number;
  submitClicked: boolean;
  onClickAttachment: Function;
  onUpdateAmount: Function;
  onDeleteDisburse: Function;
  onUpdateDisburse: Function;
  validProperties: boolean;
}
export interface ICustomerCurrencies {
  payment: any[];
  settlement: any[];
}
export const DisburseRow: FunctionComponent<DisburseProps> = (props) => {
  const { showModal } = useContext(AppContext);
  const [amount, setAmount] = useState(0);
  const [helperAmount, setHelperAmount] = useState(0);
  const [helperAmountString, setHelperAmountString] = useState('');
  const [paymentReference, setPaymentReference] = useState('');
  const [instruments, instrumentsForNonAccounts] = useInstruments();
  const [selectedInstrument, setSelectedInstrument] = useState('');
  const [disabledInstrument, setDisabledInstrument] = useState(false);
  const [beneficiaryID, setBeneficiaryID] = useState(-1);
  const [beneficiary, setBeneficiary] = useState({} as any);
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [showAdditionalProperties, setShowAdditionalProperties] = useState(false);
  const [addProperties] = useUserDefinedProperties('OutgoingFund - Online');
  const [properties, setProperties] = useState([] as any[]);
  const [paymentFee, setPaymentFee] = useState(0);
  const [feeAmountString, setFeeAmountString] = useState('');
  const [showAttachments, setShowAttachments] = useState({ id: '', show: false, posIndex: 0 });
  const [disabled, setDisabled] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState(props.beneficiaries);
  const [allowHoldFundsInstrument, setAllowHoldFundsIntrument] = useState(false);

  const [validations, setValidations] = useState({
    beneficiary: true,
    amount: true,
    instrument: true
  });

  const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];

  // --- For Input Number Scale ----------------------------------------------------------------------
  const [customerCurrencies] = useCustomerCurrencies();
  const [sellScale, setSellScale] = useState(2);
  const [buyScale, setBuyScale] = useState(2);

  useEffect(() => {
    if (props.sellCurrency != null && props.sellCurrency !== '' && (customerCurrencies as ICustomerCurrencies).settlement != null) {
      const sellcurrencyItemAux = (customerCurrencies as ICustomerCurrencies).settlement.find((currency: any) => currency.value === props.sellCurrency);
      if (sellcurrencyItemAux != null) {
      setSellScale(sellcurrencyItemAux.NumberOfDecimals);
    }
    }
    if (props.buyCurrency != null && props.buyCurrency !== '' && (customerCurrencies as ICustomerCurrencies).payment != null) {
      const buycurrencyItemAux = (customerCurrencies as ICustomerCurrencies).payment.find((currency: any) => currency.value === props.buyCurrency);
      if (buycurrencyItemAux != null) {
      setBuyScale(buycurrencyItemAux.NumberOfDecimals);
      }
    }
  }, [customerCurrencies])

  useEffect(() => {
    let listInstruments = instruments as [];
    let holdInst = listInstruments.find((i: any) => i.value === "Hold fund in Client's account");
    if (holdInst) {
      let beneList = [...props.beneficiaries];

      beneList.push({ label: "Hold fund in Client's account", value: '0', BeneficiaryID: 0, BankAccountID: 0, BeneficiaryType: 2 });
      setBeneficiaries(beneList);
      setAllowHoldFundsIntrument(true);
    }
    else {
      setBeneficiaries(props.beneficiaries);
    }
  }, [instruments, props.beneficiaries])

  useEffect(() => {
    /**key: dealObj.key,
            amount: beneObject.Amount,
            beneficiary: { BeneficiaryID: beneObject.BeneficiaryID },
            instrument: beneObject.Instrument,
            reference: beneObject.PaymentReference,
            deliveryMethod: '',
            entityProperties: beneObject.EntityProperties != null ? JSON.parse(beneObject.EntityProperties) : [],
            paymentFee: 0 */
    let disbursmentObject = props.disbursmentObject;
    if (disbursmentObject.beneficiary.BeneficiaryID != null && disbursmentObject.beneficiary.BeneficiaryID > 0) {
      //setAmount(parseFloat(disbursmentObject.amount));
      setHelperAmount(parseFloat(disbursmentObject.amount));
      setHelperAmountString(disbursmentObject.amount);
      setBeneficiaryID(Number(disbursmentObject.beneficiary.BeneficiaryID));
      setBeneficiary(disbursmentObject.beneficiary);
      setSelectedInstrument(disbursmentObject.instrument);
      //setProperties(disbursmentObject.entityProperties);
      setPaymentReference(disbursmentObject.reference);
      onUpdateBlurAmount(disbursmentObject.key, disbursmentObject.amount, disbursmentObject.beneficiary, disbursmentObject.instrument, disbursmentObject.reference, disbursmentObject.entityProperties);
      //props.onUpdateDisburse(props.objectID, disbursmentObject.amount, disbursmentObject.beneficiary, disbursmentObject.instrument, disbursmentObject.reference, deliveryMethod, disbursmentObject.entityProperties, paymentFee);
      switch (disbursmentObject.beneficiary.BeneficiaryStatus) {
        case 'Completed':
          setDisabled(true);
          break;
        case 'Void':
          setDisabled(true);
          break;

        default:
          setDisabled(false);
          break;
      }
      let propertiesAux = ValidateRequiredInvoiceDoc(disbursmentObject.entityProperties, disbursmentObject.beneficiary.BeneficiaryID);
      setProperties(propertiesAux);
    }

  }, [props.disbursmentObject]);

  useEffect(() => {
    if (props.validProperties == false) {
      setShowAdditionalProperties(true);
    }
  }, [props.validProperties]);

  const onUpdateBlurAmount = (key: any, value: any, beneficiaryObj: any, instrument: string, reference: string, entityProp: any) => {
    props.onUpdateAmount(amount, () => {
      setAmount(parseFloat(value));
      if (props.onUpdateAmount(parseFloat(value) * -1)) {
        calculateWireItemFee(customerID, props.sellCurrency, props.buyCurrency, parseFloat(value), props.customerRate, instrument, props.deliveryMethod).then((feeResponse) => {
          setPaymentFee(feeResponse.FeeAmount);
          props.onUpdateDisburse(key, parseFloat(value), beneficiaryObj, instrument, reference, deliveryMethod, entityProp, feeResponse.FeeAmount);
        });
      } else {
        setAmount(0);
        setHelperAmountString('');
        showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementAmountExceedPendingAmount'));
      }
    });
  };
  const onChangeAmount = (value: number) => {
    if (value != null) {
    setHelperAmount(value);
    setHelperAmountString(value.toString());
    }
  };

  useEffect(() => {
    if (props.submitClicked) {
      validationsFields();
    }
  }, [props.submitClicked]);

  useEffect(() => {
    if (addProperties.length > 0) {
      let newPropHelper = [] as any[];
      (addProperties as any[]).forEach((propertyElement, index) => {
        let propHelper = {} as any;
        if (properties.length > 0) {
          properties.forEach((element: any) => {
            let IsRequiredInvoice = (element.Description.toLowerCase().includes('invoice'));
            if (element.Description === propertyElement.Description) {
              propHelper = {
                Attachments: element.Attachments,
                AttachmentsRequired: IsRequiredInvoice ? element.AttachmentsRequired : propertyElement['ExtAttachmentsRequired$'],
                AmountLimit: propertyElement['ExtAmountLimit$'],
                DataType: propertyElement['Data Type'],
                DateValue: element.DateValue,
                Description: propertyElement['Description'],
                EntityPropertyID: element.EntityPropertyID,
                LookupTableAttachmentsRequired: false,
                Mandatory: IsRequiredInvoice ? element.Mandatory : propertyElement['Mandatory'],
                ExtMandatory: propertyElement['ExtMandatory$'],
                NumValue: element.NumValue,
                TextValue: element.TextValue,
                UserDefinedPropertyID: propertyElement['ID'],
                IsValid: true
              };
            }
          });
        } else {
          propHelper = {
            Attachments: [],
            AttachmentsRequired: propertyElement['ExtAttachmentsRequired$'],
            AmountLimit: propertyElement['ExtAmountLimit$'],
            DataType: propertyElement['Data Type'],
            DateValue: null,
            Description: propertyElement['Description'],
            EntityPropertyID: null,
            LookupTableAttachmentsRequired: false,
            Mandatory: propertyElement['Mandatory'],
            ExtMandatory: propertyElement['ExtMandatory$'],
            NumValue: null,
            TextValue: null,
            UserDefinedPropertyID: propertyElement['ID'],
            IsValid: true
          };
        }
        newPropHelper.push(propHelper);
      });
      setProperties(newPropHelper as any[]);
      props.onUpdateDisburse(props.objectID, amount, beneficiary, selectedInstrument, paymentReference, deliveryMethod, newPropHelper, paymentFee);
    }
  }, [addProperties]);

  const onBlurAmount = (event: any) => {
    let key = props.objectID;
    let value = event.target.value !== null && event.target.value !== '' ? event.target.value.replace(/[,]+/g, '') : 0;
    props.onUpdateAmount(amount, () => {
      setAmount(parseFloat(value));
      if (props.onUpdateAmount(parseFloat(value) * -1)) {
        validationsFields(parseFloat(value));
        calculateWireItemFee(customerID, props.buyCurrency, props.sellCurrency, parseFloat(value), props.customerRate, selectedInstrument, props.deliveryMethod).then((feeResponse) => {
          setPaymentFee(feeResponse.FeeAmount);
          setFeeAmountString(`${formatToCurrency(feeResponse.FeeAmount.toString(), props.buyCurrency)}`)

          props.onUpdateDisburse(key, parseFloat(value), beneficiary, selectedInstrument, paymentReference, deliveryMethod, properties, feeResponse.FeeAmount);
        });
      } else {
        setAmount(0);
        setHelperAmountString('');
        showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementAmountExceedPendingAmount'));
      }
    });
  };

  const onDeleteDisburse = () => {
    props.onUpdateAmount(amount, () => {
      props.onDeleteDisburse(props.objectID);
    });
  };

  const dropdownInstrumentChangeHandler = (item: any, name: string) => {
    setSelectedInstrument(item.value);
    let methods = '';
    //if (item.DeliveryMethods) methods = item.DeliveryMethods;
    //setDeliveryMethod(methods);
    if (item.value !== '') {
      validationsFields();
    }

    props.onUpdateDisburse(props.objectID, amount, beneficiary, item.value, paymentReference, methods, properties, paymentFee);
  };
  const dropdownBeneficiaryChangeHandler = (item: any, name: string) => {
    if (item.BeneficiaryID !== beneficiaryID) {
      //For Update disburse
      item.OutgoingFundsID = beneficiary.OutgoingFundsID != null ? beneficiary.OutgoingFundsID : 0;
      item.UpdateToken = beneficiary.UpdateToken != null ? beneficiary.UpdateToken : 0;
      setBeneficiaryID(Number(item.BeneficiaryID));
      setBeneficiary(item);
      let instrument = selectedInstrument;

      setSelectedInstrument('');

      if (Number(item.BankAccountID) > 0) {
        if (item.PreferredMethodOfPayment) {
          instrument = item.PreferredMethodOfPayment;
          setSelectedInstrument(instrument);
        }
      }

      /** "Hold fund in Client's account" */
      if (item.BeneficiaryID === 0) {
        setSelectedInstrument("Hold fund in Client's account");
        setDisabledInstrument(true);
      }
      else {
        setDisabledInstrument(false);
      }

      if (item.BeneficiaryID !== -1) {
        validationsFields();
      }

      let propertiesAux = ValidateRequiredInvoiceDoc([...properties], item.BeneficiaryID);
      setProperties(propertiesAux);

      props.onUpdateDisburse(props.objectID, amount, item, instrument, paymentReference, propertiesAux, paymentFee);
    }
  };

  const ValidateRequiredInvoiceDoc = (propertiesAux: any[], beneficiaryID: any) => {

    let bene = beneficiaries.find(b => b.BeneficiaryID === beneficiaryID);

    if (bene) {
      let CountryRequiresDocs = (bene.CountryRequiresDocs && bene.CountryRequiresDocs.toLowerCase() === 'yes')
      let BeneRequiresDocs = (bene.BeneRequiresDocs && bene.BeneRequiresDocs.toLowerCase() === 'yes')
      let requiredDocument = (CountryRequiresDocs && BeneRequiresDocs);
      propertiesAux.map(p => {
        if (p.Description.toLowerCase().includes('invoice')) {
          p.AttachmentsRequired = requiredDocument;
          p.Mandatory = requiredDocument;
        }
        return true;
      });

    }
    return propertiesAux;
  }

  const onChangePaymentReference = (event: any) => {
    setPaymentReference(event.target.value);
    props.onUpdateDisburse(props.objectID, amount, beneficiary, selectedInstrument, event.target.value, deliveryMethod, properties, paymentFee);
  };

  const onClickAmount = () => {};

  const validationsFields = (amountHelp?: number) => {
    var bene = validInputClassAllowZero(beneficiaryID.toString());
    var instr = validInputClass(selectedInstrument);
    var amo = validInputClass(amountHelp ? amountHelp.toString() : amount.toString());
    setValidations({
      beneficiary: bene,
      instrument: instr,
      amount: amo
    });
  };

  const onClickMore = () => {
    setShowAdditionalProperties(!showAdditionalProperties);
  };

  const dropdownPropertyChange = (item: any, name: string, index: number) => {
    let propertiesHelper = [...properties];

    let propHelper = {
      Attachments: propertiesHelper[index] ? (propertiesHelper[index].Attachments ? propertiesHelper[index].Attachments : []) : [],
      AttachmentsRequired: (addProperties as any[])[index]['ExtAttachmentsRequired$'],
      AmountLimit: (addProperties as any[])[index]['ExtAmountLimit$'],
      DataType: (addProperties as any[])[index]['Data Type'],
      DateValue: null,
      Description: (addProperties as any[])[index]['Description'],
      EntityPropertyID: propertiesHelper[index].EntityPropertyID,
      LookupTableAttachmentsRequired: false,
      Mandatory: (addProperties as any[])[index]['Mandatory'],
      ExtMandatory: (addProperties as any[])[index]['ExtMandatory$'],
      NumValue: null,
      TextValue: item.value,
      UserDefinedPropertyID: (addProperties as any[])[index]['ID'],
      IsValid: true
    };

    propertiesHelper[index] = propHelper;

    setProperties(propertiesHelper);
    props.onUpdateDisburse(props.objectID, amount, beneficiary, selectedInstrument, paymentReference, deliveryMethod, propertiesHelper, paymentFee);
  };

  const textPropertyChange = (event: any, index: number) => {
    let propertiesHelper = [...properties];

    let IsRequiredInvoice = (propertiesHelper[index].Description.toLowerCase().includes('invoice'));

    let propHelper = {
      Attachments: propertiesHelper[index] ? (propertiesHelper[index].Attachments ? propertiesHelper[index].Attachments : []) : [],
      AttachmentsRequired: IsRequiredInvoice ? propertiesHelper[index].AttachmentsRequired : (addProperties as any[])[index]['ExtAttachmentsRequired$'],
      AmountLimit: (addProperties as any[])[index]['ExtAmountLimit$'],
      DataType: (addProperties as any[])[index]['Data Type'],
      DateValue: null,
      Description: (addProperties as any[])[index]['Description'],
      EntityPropertyID: propertiesHelper[index].EntityPropertyID,
      LookupTableAttachmentsRequired: false,
      Mandatory: IsRequiredInvoice ? propertiesHelper[index].Mandatory : (addProperties as any[])[index]['Mandatory'],
      ExtMandatory: (addProperties as any[])[index]['ExtMandatory$'],
      NumValue: null,
      TextValue: event.target.value,
      UserDefinedPropertyID: (addProperties as any[])[index]['ID'],
      IsValid: true
    };

    propertiesHelper[index] = propHelper;

    setProperties(propertiesHelper);
    props.onUpdateDisburse(props.objectID, amount, beneficiary, selectedInstrument, paymentReference, deliveryMethod, propertiesHelper, paymentFee);
  };

  const numberPropertyChange = (event: any, index: number) => {
    let propertiesHelper = [...properties];

    let propHelper = {
      Attachments: propertiesHelper[index] ? (propertiesHelper[index].Attachments ? propertiesHelper[index].Attachments : []) : [],
      AttachmentsRequired: (addProperties as any[])[index]['ExtAttachmentsRequired$'],
      AmountLimit: (addProperties as any[])[index]['ExtAmountLimit$'],
      DataType: (addProperties as any[])[index]['Data Type'],
      DateValue: null,
      Description: (addProperties as any[])[index]['Description'],
      EntityPropertyID: propertiesHelper[index].EntityPropertyID,
      LookupTableAttachmentsRequired: false,
      Mandatory: (addProperties as any[])[index]['Mandatory'],
      ExtMandatory: (addProperties as any[])[index]['ExtMandatory$'],
      NumValue: Number(event.target.value),
      TextValue: null,
      UserDefinedPropertyID: (addProperties as any[])[index]['ID'],
      IsValid: true
    };

    propertiesHelper[index] = propHelper;

    setProperties(propertiesHelper);
    props.onUpdateDisburse(props.objectID, amount, beneficiary, selectedInstrument, paymentReference, deliveryMethod, propertiesHelper, paymentFee);
  };

  const onCloseAttachments = () => {
    setShowAttachments({ id: '', show: false, posIndex: 0 });
  };

  const addedAttachment = (attachmentID: string, fileName: string, description: string, index: number) => {
    let propertiesHelper = [...properties];
    propertiesHelper[index].Attachments.push({
      ID: attachmentID,
      FileName: fileName,
      Description: description
    });

    //propertiesHelper[index] = propHelper;

    setProperties(propertiesHelper);
    props.onUpdateDisburse(props.objectID, amount, beneficiary, selectedInstrument, paymentReference, deliveryMethod, propertiesHelper, paymentFee);
    showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Message_AttachmentUploaded'));
  };

  const deleteAttachment = (attachmentIndex: any, propertyIndex: number) => {
    let propertiesHelper = [...properties];
    //propertiesHelper[propertyIndex].Attachments.splice(attachment.posIndex, 1);

    if (attachmentIndex >= 0) {
      propertiesHelper[propertyIndex].Attachments.splice(attachmentIndex, 1);
      setProperties(propertiesHelper);
      props.onUpdateDisburse(props.objectID, amount, beneficiary, selectedInstrument, paymentReference, deliveryMethod, propertiesHelper, paymentFee);
    }
  };

  const renderProperties = () => {
    let rows = [] as any[];
    (addProperties as any[]).forEach((propertyElement, index) => {
      (properties as any[]).forEach((propElement: any) => {
        if (propertyElement.Description === propElement.Description) {
          propertyElement.TextValue = propElement.TextValue;
          propertyElement.NumValue = propElement.NumValue;
          propertyElement.DateValue = propElement.DateValue;
          propertyElement.Attachments = propElement.Attachments;
          propertyElement.EntityPropertyID = propElement.EntityPropertyID;
          if (propertyElement.Description.toLowerCase().includes('invoice')) {
            propertyElement.IsRequiredInvoice = true;
            propertyElement['Mandatory'] = propElement.Mandatory;
            propertyElement.AttachmentsRequired = propElement.AttachmentsRequired;
          }
        }
      });
      var propertyControl;

      let isMandatory = false;
      let attachmentRequired = false;
      var paymentAmountInBaseCurrency = Number(amount) * props.baseCurrencyDirectRate;

      if (propertyElement.IsRequiredInvoice && propertyElement.AttachmentsRequired) {
        attachmentRequired = true;
      }

      if (propertyElement['Mandatory']) {
        isMandatory = true;
      }
      else {
        if (propertyElement['ExtAmountLimit$'] != null && paymentAmountInBaseCurrency > Number(propertyElement['ExtAmountLimit$'])) {
        if (propertyElement['ExtMandatory$']) {
          isMandatory = true;
        }
        if (propertyElement['ExtAttachmentsRequired$']) {
          attachmentRequired = true;
        }
      }
      }
      let isCompleted = true;
      let attachmentCompleted = true;

      if (attachmentRequired && propertyElement.Attachments.length === 0) {
        attachmentCompleted = false;
      }

      if (propertyElement['PossibleValues$']) {
        console.log(propertyElement['PossibleValues$']);
        propertyElement['PossibleValues$'].forEach((element: any) => {
          element.value = element.Value;
          element.label = element.Description;
        });

        //let isCompleted = true;

        if (isMandatory && (propertyElement.TextValue === null || propertyElement.TextValue === '')) {
          isCompleted = false;
        }

        propertyControl = (
          <Dropdown
            id={propertyElement.Description}
            className={formatValidInputClass(isCompleted)}
            title={propertyElement['PossibleValues$'][0].Description}
            onChange={(item: any, name: string) => {
              dropdownPropertyChange(item, name, index);
            }}
            select={{ value: propertyElement.TextValue != null ? propertyElement.TextValue : '' }}
            name={propertyElement.Description}
            list={propertyElement['PossibleValues$']}
          />
        );
      } else {
        switch (propertyElement['Data Type']) {
          case 'Text':
          default:
            if (isMandatory && (propertyElement.TextValue === null || propertyElement.TextValue === '')) {
              isCompleted = false;
            }
            propertyControl = (
              <input
                type="text"
                value={propertyElement.TextValue}
                className={formatValidInputClass(isCompleted)}
                onChange={(event) => {
                  textPropertyChange(event, index);
                }}
              />
            );
            break;
          case 'Numeric':
            if (isMandatory && (propertyElement.NumValue === null || propertyElement.NumValue === '')) {
              isCompleted = false;
            }
            propertyControl = (
              <input
                type="number"
                className={formatValidInputClass(isCompleted)}
                value={propertyElement.NumValue}
                onChange={(event) => {
                  numberPropertyChange(event, index);
                }}
              />
            );
            break;
          case 'DateTime':
            propertyControl = <DatePicker value={propertyElement.DateValue} />;
            break;
        }
      }
      //let randomNumber = Math.random() * 100000;
      rows.push(
        <div className="row">
          <div className="col-md-4 col-xs-12 row">
            <div className="row">
              <span>{propertyElement.Description}</span>
            </div>
          </div>
          <div className="col-md-5 col-xs-12">
            <div className="row">{propertyControl}</div>
          </div>
          <div className="col-md-1 col-xs-12 row middle-xs">
            <Badge count={propertyElement.Attachments ? propertyElement.Attachments.length : 0} size="small" offset={[5, 0]}>
            <PaperClipOutlined
              className={formatIconClass(attachmentCompleted)}
              onClick={() => {
                /*setShowAttachments({ id: `disburse-${index}`, show: true, posIndex: index })*/ props.onClickAttachment(index, properties[index].Attachments, addedAttachment, deleteAttachment);
              }}
            />
            </Badge>
          </div>
        </div>
      );
    });
    return rows;
  };

  let newInstruments = instrumentsForNonAccounts as [];
  if (Number(beneficiary.BankAccountID) > 0) {
    newInstruments = instruments as [];
  }

  let listInstruments = [];

  if (beneficiaryID > 0) {
    listInstruments = newInstruments.filter((i: any) => i.value !== "Hold fund in Client's account");
  }
  else {
    if (beneficiaryID === 0 && allowHoldFundsInstrument) {
      listInstruments.push({ label: "Hold fund in Client's account", value: "Hold fund in Client's account" });
    }
    else {
      listInstruments = newInstruments;
    }
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <span className="label-visible">{getResource('Js_TH_Beneficiary')}</span>
          <div className="row">
            <DropdownFlag
              className={formatValidInputClass(validations.beneficiary)}
              id="beneId"
              name="beneficiaryDropdown"
              title={getResource('SelectBeneficiary')}
              list={beneficiaries}
              onChange={dropdownBeneficiaryChangeHandler}
              select={{ value: beneficiary.value }}
              disabled={disabled}
              countryField={'CountryISOCode'}
            />
          </div>
        </div>
        <div className="col-md-3 col-xs-12">
          <span className="label-visible">{getResource('Label.Instrument')}</span>
          <div className="row">
            <Dropdown
              className={formatValidInputClass(validations.instrument)}
              id="instrumentId"
              name="instrumentDropdown"
              title={getResource('SelectInstrument')}
              list={listInstruments}
              onChange={dropdownInstrumentChangeHandler}
              select={{ value: selectedInstrument }}
              disabled={disabled || disabledInstrument}
            />
          </div>
        </div>
        <div className="col-md-2 col-xs-12">
          <span className="label-visible">{getResource('Label.WeSend')}</span>
          <div className="row">
            <Input type="number" className={formatValidInputClass(validations.amount)} value={helperAmountString} onValueChange={(value: number) => onChangeAmount(value)} onBlur={onBlurAmount} disabled={disabled} decimalScale={sellScale} />
          </div>
        </div>
        <div className="col-md-1 col-xs-12">
          <span className="label-visible">{getResource('Label.Fee')}</span>
          <div className="row">
            <input type="text" value={feeAmountString} disabled={true} />
          </div>
        </div>
        <div className="col-md-1 col-xs-12">
          <span className="label-visible">{getResource('Label.Reference')}</span>
          <div className="row">
            <input type="text" value={paymentReference} onChange={onChangePaymentReference} disabled={disabled} />
          </div>
        </div>
        <div className="col-md-1 col-xs-12 center">
          <DeleteTwoTone style={{ marginRight: '20px', marginTop: '10px' }} onClick={onDeleteDisburse} twoToneColor="#34c521" disabled={disabled} />
          <PlusCircleTwoTone style={{ marginTop: '10px' }} onClick={onClickMore} twoToneColor="#34c521" disabled={disabled} />
        </div>
      </div>
      {showAdditionalProperties && renderProperties()}
      <Divider className='label-visible' style={{ marginBottom: '10px' }} />
    </React.Fragment>
  );
};

class PaymentDisburse extends React.Component<PaymentDisburseProps> {
  state = {
    amount: this.props.amount,
    disburses: [{ key: 0, amount: 0, beneficiary: { BeneficiaryID: 0 }, instrument: '', reference: '', deliveryMethod: '', entityProperties: [] as any[], paymentFee: 0, validProperties: true }],
    beneficiaries: [],
    showAttachments: { id: '', show: false, posIndex: 0 },
    attachmentsList: [],
    updateAttachmentFunction: null,
    deleteAttachmentFunction: null,
    baseCurrencyDirectRate: 1,
    submitClick: false,
    currencyGroup: '',
    currencies: []
  };

  static contextType = AppContext;

  componentDidMount() {
    const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];
    getCurrenciesByCustomer(customerID).then((jsonCcys: any) => {
      if (jsonCcys.currencies != undefined) {
        var paymentCurrencies = jsonCcys.currencies.filter((c: any) => c.Type == 'Payment');
        if (paymentCurrencies != undefined) {
          var buycurrencyItemAux = paymentCurrencies.find((currency: any) => currency.CurrencyID === this.props.sellCurrency); // it was flipped
          var group = '';

          if (buycurrencyItemAux != null) {
            group = buycurrencyItemAux.CurrencyType;
          }
          this.setState({ currencies: paymentCurrencies, currencyGroup: group });
        }
      }
    });

    if (this.props.dealHeaderID != null) {
      getDealByID(this.props.dealHeaderID).then((dealResponse: any) => {
        this.setState({ baseCurrencyDirectRate: dealResponse.deals[0].BaseCurrencyDirectRate });
      });
      getFullDealHeader(this.props.dealHeaderID).then((dealResponse: any) => {
        const beneObject = dealResponse.OutgoingFunds != null ? (dealResponse.OutgoingFunds.length > 0 ? dealResponse.OutgoingFunds : []) : [];
        let helper = [] as any;
        beneObject.forEach((element: any, index: number) => {
          if (element.BeneficiaryID != null) {
            helper.push({
              key: index,
              amount: element.PaymentAmount,
              beneficiary: {
                OutgoingFundsID: element.OutgoingFundsID,
                BankAccountID: element.BeneficiaryBankAccountID,
                BeneficiaryBankAccountName: '',
                BeneficiaryID: element.BeneficiaryID,
                BeneficiaryName: element.Name,
                BeneficiaryStatus: element.Status,
                BeneficiaryType: element.BeneficiaryType,
                CountryName: element.CountryName,
                Description: '',
                PreferredDeliveryMethod: '',
                PreferredMethodOfPayment: element.PaymentInstrument,
                Value: element.BeneficiaryID + '|' + element.BeneficiaryBankAccountID,
                label: '',
                value: element.BeneficiaryID + '|' + element.BeneficiaryBankAccountID,
                UpdateToken: element.UpdateToken
              },
              instrument: element.PaymentInstrument,
              reference: element.PaymentReference,
              deliveryMethod: '',
              entityProperties: element.EntityProperties != null ? element.EntityProperties : [],
              //entityProperties: element.EntityProperties != null ? JSON.parse(element.EntityProperties) : [],
              paymentFee: 0
            });
          }
          this.setState({ disburses: helper });
        });
      });
    }
    getBeneficiaryForDisbursementsScreen(customerID, this.props.sellCurrency).then((jsonBene: any) => {
      if (jsonBene.beneficiaries) {
        let finalJsonBene = jsonBene.beneficiaries.filter((item: any) => item.BeneficiaryStatus === 'Authorized').map((c: any) => ({ ...c, label: c.Description, value: c.Value, BeneficiaryType: 0 }));

        finalJsonBene.push({ label: getResource('SelectBeneficiary'), value: '' });

        this.setState({ beneficiaries: finalJsonBene });
      } else {
        if (this.props.onError != null) {
          this.props.onError(getResource('Message_Beneficiary') + ` ${this.props.sellCurrency}.`);
        }
      }
    });

  };

  onUpdateAmount = (fixAmountParam: number, nextStep: Function) => {
    let amountHelper = this.state.amount + fixAmountParam;
    if (amountHelper < 0 || amountHelper > this.props.amount) {
      return false;
    } else {
      this.state.amount = amountHelper;
      this.setState({ amount: amountHelper }, () => {
        if (nextStep) nextStep();
      });
      return true;
    }
  };

  onUpdateDisburse = (key: number, amount: number, beneficiary: any, instrument: string, reference: string, deliveryMethod: string, entityProperties: any[], paymentFee: number) => {
    let disburseHelper = [...this.state.disburses];
    for (let i = 0; i < disburseHelper.length; i++) {
      if (disburseHelper[i].key === key) {
        disburseHelper[i].amount = amount;
        disburseHelper[i].beneficiary = beneficiary;
        disburseHelper[i].instrument = instrument;
        disburseHelper[i].reference = reference;
        disburseHelper[i].deliveryMethod = deliveryMethod;
        disburseHelper[i].entityProperties = entityProperties;
        disburseHelper[i].paymentFee = paymentFee;
      }
    }

    this.setState({ disburses: disburseHelper });
  };

  onAddClick = () => {
    let valid = true;
    this.state.disburses.forEach((ele: any) => {
      if (ele.amount === 0) {
        valid = false;
      }
    });
    if (valid) {
      if (this.state.amount > 0) {
        let disburseHelper = [...this.state.disburses];
        disburseHelper.push({ key: disburseHelper.length, amount: 0, beneficiary: { BeneficiaryID: 0 }, instrument: '', reference: '', deliveryMethod: '', entityProperties: [] as any[], paymentFee: 0, validProperties: true });
        this.setState({ disburses: disburseHelper });
      } else {
        this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementBalance'));
      }
    } else {
      this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
    }
  };

  onDeleteDisburse = (key: number) => {
    let disburseHelper = [...this.state.disburses];
    disburseHelper.splice(key, 1);
    this.setState({ disburses: disburseHelper });
  };

  onSubmitClick = () => {
    this.setState({ submitClick: !this.state.submitClick });
    // add submit validation
    let outgoingFunds = [] as any[];
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    let valid = true;
    debugger
    this.state.disburses.forEach((disbElement: any) => {
      if (disbElement.amount === 0) {
        valid = false;
        this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
        return;
      }

      if (disbElement.beneficiary.BeneficiaryID === -1) {
        valid = false;
        this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
        return;
      }

      if (disbElement.instrument === '') {
        valid = false;
        this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
        return;
      }

      var paymentAmountInBaseCurrency = Number(disbElement.amount) * this.state.baseCurrencyDirectRate;
      disbElement.validProperties = true;

      let CountryRequiresDocs = (disbElement.beneficiary.CountryRequiresDocs && disbElement.beneficiary.CountryRequiresDocs.toLowerCase() === 'yes')
      let BeneRequiresDocs = (disbElement.beneficiary.BeneRequiresDocs && disbElement.beneficiary.BeneRequiresDocs.toLowerCase() === 'yes')
      let requiredDocument = (CountryRequiresDocs && BeneRequiresDocs);

      disbElement.entityProperties.forEach((entityProperty: any) => {

        if (entityProperty.Mandatory) {
          switch (entityProperty.DataType) {
            case 'Text':
            default:
              if (entityProperty.TextValue === '' || entityProperty.TextValue === null) {
                valid = false;
                debugger
                disbElement.validProperties = false;
                if (requiredDocument && entityProperty.Description.toLowerCase().includes('invoice')) {
                  this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinishRequiredDoc'));
                  return;
                }
                else {
                this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
                return;
                }
              }
              break;
            case 'Numeric':
              if (entityProperty.NumValue === '' || entityProperty.NumValue === null) {
                valid = false;
                disbElement.validProperties = false;
                this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
                return;
              }
              break;
            case 'DateTime':
              if (entityProperty.DateValue === '' || entityProperty.DateValue === null) {
                valid = false;
                disbElement.validProperties = false;
                this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
                return;
              }
              break;
          }

          if (entityProperty.AttachmentsRequired && requiredDocument && entityProperty.Description.toLowerCase().includes('invoice')) {
            if (entityProperty.Attachments && entityProperty.Attachments.length === 0) {
              valid = false;
              disbElement.validProperties = false;
              this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinishRequiredDoc'));
              return;
            }
          }
        }
        else {
          if (entityProperty.AmountLimit != null && paymentAmountInBaseCurrency > Number(entityProperty.AmountLimit)) {
            if (entityProperty.ExtMandatory) {
            switch (entityProperty.DataType) {
              case 'Text':
              default:
                if (entityProperty.TextValue === '' || entityProperty.TextValue === null) {
                  valid = false;
                  disbElement.validProperties = false;
                  this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
                  return;
                }
                break;
              case 'Numeric':
                if (entityProperty.NumValue === '' || entityProperty.NumValue === null) {
                  valid = false;
                  disbElement.validProperties = false;
                  this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
                  return;
                }
                break;
              case 'DateTime':
                if (entityProperty.DateValue === '' || entityProperty.DateValue === null) {
                  valid = false;
                  disbElement.validProperties = false;
                  this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
                  return;
                }
                break;
            }
          }

          if (entityProperty.AttachmentsRequired) {
            if (entityProperty.Attachments.length === 0) {
              valid = false;
              disbElement.validProperties = false;
              this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DisbursementUnfinish'));
              return;
            }
          }
        }
        }
      });

      let objectDisb = {} as any;
      objectDisb.DealHeaderID = this.props.dealHeaderID;
      objectDisb.BeneficiaryID = disbElement.beneficiary.BeneficiaryID;
      objectDisb.PaymentAmount = disbElement.amount;
      objectDisb.PaymentReference = disbElement.reference;
      objectDisb.BeneficiaryType = disbElement.beneficiary.BeneficiaryType;
      objectDisb.DeliveryMethod = disbElement.deliveryMethod;
      objectDisb.PaymentInstrument = disbElement.instrument;
      objectDisb.EntityPropertiesJson = JSON.stringify(disbElement.entityProperties);
      objectDisb.CreatedBy = localStorage.getItem('UserID');
      objectDisb.UpdatedBy = localStorage.getItem('UserID');
      objectDisb.CreatedOn = new Date();
      objectDisb.UpdateOn = new Date();
      objectDisb.WireItemFee = disbElement.paymentFee;
      objectDisb.ValueDate = this.props.valueDate;
      objectDisb.Status = 'Pending';
      //objectDisb.Status = userSession.DealApproveRequired ? 'Pending' : 'Completed';
      if (disbElement.beneficiary.BankAccountID) {
        objectDisb.BeneficiaryBankAccountID = disbElement.beneficiary.BankAccountID;
      }
      if (disbElement.beneficiary.OutgoingFundsID) {
        objectDisb.OutgoingFundsID = disbElement.beneficiary.OutgoingFundsID;
      }
      if (disbElement.beneficiary.UpdateToken) {
        objectDisb.UpdateToken = disbElement.beneficiary.UpdateToken;
      }
      /*if (disbElement.beneficiary.BeneficiaryStatus) {
        objectDisb.Status = disbElement.beneficiary.BeneficiaryStatus;
      }*/
      outgoingFunds.push(objectDisb);
    });
    if (valid) {
      saveOutgoingFunds(outgoingFunds).then((ofResponse) => {
        if (ofResponse.httpStatusCode === 200) {
          this.props.onSubmit();
        } else {
          this.context.showModal(getResource('Js_MessageError_TitleError'), getResource('Message_Unexpected'));
        }
      });
    }
  };

  onCloseAttachments = () => {
    this.setState({ showAttachments: { id: '', show: false, posIndex: 0 } });
  };

  onClickAttachment = (posIndex: any, attachmentsList: any, updateFunction: any, deleteFunction: any) => {
    this.setState({ showAttachments: { id: '', show: true, posIndex: posIndex }, attachmentsList: attachmentsList, updateAttachmentFunction: updateFunction, deleteAttachmentFunction: deleteFunction });
  };

  onExitClick = () => {
    if (this.props.onBack) {
      this.props.onBack();
    }
  }

  render = () => {
    let arrayOfDisburses = [];
    for (let index = 0; index < this.state.disburses.length; index++) {
      var validProperties = this.state.disburses[index].validProperties != null ? this.state.disburses[index].validProperties : true;

      arrayOfDisburses.push(
        <DisburseRow
          key={`disburse-row-${index}`}
          objectID={index}
          disbursmentObject={this.state.disburses[index]}
          beneficiaries={this.state.beneficiaries}
          instrument={this.props.settlementInstrument}
          customerRate={this.props.customerRate}
          deliveryMethod={this.props.deliveryMethod}
          sellCurrency={this.props.sellCurrency}
          buyCurrency={this.props.buyCurrency}
          onUpdateAmount={this.onUpdateAmount}
          onDeleteDisburse={this.onDeleteDisburse}
          onUpdateDisburse={this.onUpdateDisburse}
          onClickAttachment={this.onClickAttachment}
          baseCurrencyDirectRate={this.state.baseCurrencyDirectRate}
          submitClicked={this.state.submitClick}
          validProperties={validProperties}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="screen-container">
          <div className="row center-xs">
            <div className="col-md-12">
              <div className="row start-xs around-xs">
                <div className="col-md-5 col-xs-12 row middle-xs">
                  <span className="subtitle-text">{getResource('Label.DealID')}: {this.props.dealHeaderID}</span>
                </div>                
              </div>
              <div className="row start-xs around-xs">
                <div className="col-md-5 col-xs-12 row middle-xs">
                  <span className="subtitle-text">
                    {getResource('Label.AmountDisburse')}: {formatToCurrency(this.state.amount.toString(), this.props.sellCurrency)} {this.props.sellCurrency}
                  </span>
                </div>
                {
                  (this.state.currencyGroup != 'Exotic' || (this.state.currencyGroup == 'Exotic' && arrayOfDisburses.length == 0))
                  && <div className="col-md-7 col-xs-12" style={{ textAlign: 'right' }}>
                  <Button type="secondary" onClick={this.onAddClick}>
                    {getResource('Button.Add')}
                  </Button>
                  </div>}
              </div>
              <div className="row">
                <Divider />
                <div className="col-md-4 col-xs-12 header-hidden">
                  <span>{getResource('Js_TH_Beneficiary')}</span>
                </div>
                <div className="col-md-3 col-xs-12 header-hidden">
                  <span>{getResource('Label.Instrument')}</span>
                </div>
                <div className="col-md-2 col-xs-12 header-hidden">
                  <span>{getResource('Label.WeSend')} ({this.props.sellCurrency})</span>
                </div>
                <div className="col-md-1 col-xs-12 header-hidden">
                  <span>{getResource('Label.Fee')} ({this.props.buyCurrency})</span>
                </div>
                <div className="col-md-1 col-xs-12 header-hidden">
                  <span>{getResource('Label.Reference')}</span>
                </div>
                <div className="col-md-1 col-xs-12 header-hidden">
                  <span>{getResource('Table_TH_Actions')}</span>
                </div>
                {arrayOfDisburses}
                <Divider />
                <div className="row-actions">
                  <Button type="secondary" onClick={this.onExitClick}>
                    {getResource('Button.Cancel')}
                  </Button>
                    <Button type="primary" onClick={this.onSubmitClick}>
                      {getResource('Button.Submit')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AttachmentsForm
          id={'attachment-disburse-form'}
          show={this.state.showAttachments}
          showList={true}
          attachmentsList={this.state.attachmentsList}
          onClose={this.onCloseAttachments}
          useLinkID={false}
          onUploadWithoutLink={(attachmentID, fileName, description) => {
            //@ts-ignore
            this.state.updateAttachmentFunction(attachmentID, fileName, description, this.state.showAttachments.posIndex);
            //addedAttachment(attachmentID, fileName, description, showAttachments.posIndex);
          }}
          onClickDeleteAttachment={(attachment: any) => {
            //@ts-ignore
            this.state.deleteAttachmentFunction(attachment, this.state.showAttachments.posIndex);
          }}
        />
      </React.Fragment>
    );
  };
}

export default PaymentDisburse;
