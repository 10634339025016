/* eslint-disable react/destructuring-assignment */
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ArrowDown from './assets/arrowDown.svg';
import ArrowUp from './assets/arrowUp.svg';

/* 
<Dropdown
  name="location"
  title="Select location"
  searchable={["Search for location", "No matching location"]}
  list={locations}
  onChange={this.onChange}
/>
*/

class Dropdown extends Component {
  constructor(props) {
    super(props);
    const { title, list } = this.props;

    this.state = {
      isListOpen: false,
      title,
      selectedItem: null,
      keyword: '',
      list,
    };

    this.searchField = React.createRef();
  }

  componentDidMount() {
    const { select } = this.props;

    if (select) {
      this.selectSingleItem(select);
    }
  }

  componentDidUpdate(prevProps) {

    const { isListOpen, selectedItem } = this.state;

    setTimeout(() => {
      if (isListOpen) {
        window.addEventListener('click', this.close);
      } else {
        window.removeEventListener('click', this.close);
      }
    }, 0);

    const { select } = this.props;
    const prevSelect = prevProps.select;

    if (prevSelect === null) {
      return;
    }

    if (select && prevSelect !== select)// && (selectedItem.label.toLowerCase().includes('select'))
    {
      this.selectSingleItem(select);
    }

    if (selectedItem === null && prevSelect !== undefined && prevSelect.value !== undefined) {
      this.selectSingleItem(prevSelect.value);
    }

    if ((prevProps.list !== this.props.list) && (this.props.list === null || this.props.list === undefined || this.props.list.length === 0)) {
      this.setState({
        title: this.props.title,
        isListOpen: false,
        selectedItem: null,
      });
    }

    /* STATE
      isListOpen: false
      keyword: ""
      list: (7) [{…}, {…}, {…}, {…}, {…}, {…}, {…}]
      selectedItem: null
      title: "Select a Beneficiary"
      [[Prototype]]: Object
    */
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { list } = nextProps;

    if (JSON.stringify(list) !== JSON.stringify(prevState.list)) {
      return { list };
    }

    return null;
  }

  close = () => {
    this.setState({
      isListOpen: false,
    });
  }

  clearSelection = () => {
    const { name, title, onChange } = this.props;

    this.setState({
      selectedItem: null,
      title,
    }, () => {
      onChange(null, name);
    });
  }

  selectSingleItem = (item) => {
    if (item === undefined || item === null) {
      return
    }

    const { list } = this.props;
    if (list) {
      const selectedItem = list.find((i) => i.value === item.value);
      if (selectedItem) {
        this.selectItem(selectedItem);
      }
    }
  }

  selectItem = (item) => {
    const { label, value } = item;
    const { list, selectedItem } = this.state;
    const { name, onChange } = this.props;

    let foundItem;

    if (!label) {
      foundItem = list.find((i) => i.value === item.value);
    }

    this.setState({
      title: label || foundItem.label,
      isListOpen: false,
      selectedItem: item,
    }, () => selectedItem?.value !== value && onChange(item, name));
  }

  toggleList = () => {
    this.setState((prevState) => ({
      isListOpen: !prevState.isListOpen,
      keyword: '',
    }), () => {
      if (this.state.isListOpen && this.searchField.current) {
        this.searchField.current.focus();
        this.setState({
          keyword: '',
        });
      }
    });
  }

  filterList = (e) => {
    e.preventDefault();
    this.setState({
      keyword: e.target.value.toLowerCase(),
    });
  }

  listItems = () => {
    if (this.props.list && this.props.list.length > 0) {
    const {
      id,
      searchable,
      styles,
    } = this.props;
    const { listItem, listItemNoResult } = styles;
    const { keyword, list } = this.state;
    let tempList = [...list];
    const selectedItemValue = this.state.selectedItem?.value;

    if (keyword.length) {
      tempList = list.filter((item) => item.label.toLowerCase().includes(keyword.toLowerCase()));
    }

    if (tempList.length) {
      return tempList.map((item) => {
        if (this.props.settlementMode) {
          console.log(item);
          if (item.value !== '') {
            return (
              <button
                id={`${item.value}`}
                type="button"
                className={`${item.value === selectedItemValue ? 'dd-list-item-selected' : 'dd-list-item'} ${id}`}
                style={listItem}
                key={item.value}
                onClick={() => this.selectItem(item)}
                disabled={item.disabled || false}
              >
                {item.details.map((detail) => {
                  return (
                    <p>
                      {/*detail.label}: {detail.description*/}
                      {detail.description}
                    </p>
                  );
                })}
                <Divider style={{ margin: 0, padding: 0 }} />
              </button>
            );
          }
        } else {
          if (item.value !== '') {
            return (
              <button
                id={`${item.value}`}
                type="button"
                className={`${item.value === selectedItemValue ? 'dd-list-item-selected' : 'dd-list-item'} ${id}`}
                style={listItem}
                key={item.value}
                onClick={() => this.selectItem(item)}
                disabled={item.disabled || false}
              >
                {item.label}{' '}
                <Divider style={{ margin: 0, padding: 0 }} />
              </button>
            );
          }
        }
      });
    }

    return (
      <div
        className={`dd-list-item no-result ${id}`}
        style={listItemNoResult}
      >
        {searchable[1]}
      </div>
    );
  }
  }

  render() {
    const {
      id,
      searchable,
      arrowUpIcon,
      arrowDownIcon,
      styles,
      submit,
      errorMessage
    } = this.props;
    const { isListOpen, title } = this.state;

    const {
      wrapper,
      header,
      headerTitle,
      headerArrowUpIcon,
      headerArrowDownIcon,
      list,
      listSearchBar,
      scrollList,
    } = styles;

    return (
      <>
        <div className={`dd-wrapper ${id}`} style={wrapper}>
          <button type="button" className={this.props.className != null ? this.props.className + ' dd-header' : `dd-header ${this.props.disabled ? 'dd-header-disabled' : ''} ${id}`} style={header} onClick={this.toggleList} disabled={this.props.disabled || false}>
            <div className={`dd-header-title ${id}`} style={headerTitle}>
              {title}
            </div>
            {isListOpen ? <span style={headerArrowUpIcon}>{arrowUpIcon || <img alt="" src={ArrowUp} />}</span> : <span style={headerArrowDownIcon}>{arrowDownIcon || <img alt="" src={ArrowDown} />}</span>}
          </button>
          {isListOpen && (
            <div className={`dd-list${searchable ? ' searchable' : ''} ${id}`} style={list}>
              {searchable && (
                <input ref={this.searchField} className={`dd-list-search-bar ${id}`} style={listSearchBar} placeholder={searchable[0]} onClick={(e) => e.stopPropagation()} onChange={(e) => this.filterList(e)} />
              )}
              <div className={`dd-scroll-list ${id}`} style={scrollList}>
                {this.listItems()}
              </div>
            </div>
          )}
        </div>
        {(this.state.selectedItem === null || this.state.selectedItem.value === '' || this.state.selectedItem.value === '0') && submit && (
          <div className="row">
            <div className="ant-form-item-explain ant-form-item-explain-error">
              <div role="alert">{errorMessage === undefined ? 'Required!' : errorMessage}</div>
            </div>
          </div>
        )}
      </>
    );
  }
}

Dropdown.defaultProps = {
  id: '',
  select: undefined,
  searchable: undefined,
  styles: {},
  arrowUpIcon: null,
  arrowDownIcon: null,
  checkIcon: null,
};

Dropdown.propTypes = {
  id: PropTypes.string,
  styles: PropTypes.shape({
    wrapper: PropTypes.string,
    header: PropTypes.string,
    headerTitle: PropTypes.string,
    headerArrowUpIcon: PropTypes.string,
    headerArrowDownIcon: PropTypes.string,
    checkIcon: PropTypes.string,
    list: PropTypes.string,
    listSearchBar: PropTypes.string,
    scrollList: PropTypes.string,
    listItem: PropTypes.string,
    listItemNoResult: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  select: PropTypes.shape({ value: PropTypes.string }),
  searchable: PropTypes.shape([PropTypes.string, PropTypes.string]),
  checkIcon: PropTypes.elementType,
  arrowUpIcon: PropTypes.elementType,
  arrowDownIcon: PropTypes.elementType,
  submit: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default Dropdown;