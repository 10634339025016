import { genericGetWithParameters } from './Networking';

export async function getBranchInformationByBranchID(branchID: number) {
  try {
      let params = {
        branchID: branchID
      }
      let response = await genericGetWithParameters('Branch/GetBranchInformationByBranchID', params);
      return response;
  } catch (error) {
      console.error(error);
  }  
}

export async function getOnlineBanks(nameLike: string, countryID: string, searchBy: string) {
  try {
    let params = {
      NameLike: nameLike,
      CountryID: countryID,
      Page: 0,
      Items: 10,
      SearchBy: searchBy
    };

    let response = await genericGetWithParameters('Bank/GetOnlineBanks', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getOnlineBankBranches(bankName: string, branchDescription: string, countryID: string, bankId: number) {
  try {
    let params = {
      BankName: bankName,
      BranchDescription: branchDescription,
      Page: 0,
      Items: 10,
      CountryID: countryID,
      BankID: bankId
    };

    let response = await genericGetWithParameters('Bank/GetOnlineBankBranches', params);
    return response;
  } catch (error) {
    console.error(error);
  }
}