import { Button } from 'antd';
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../../App.context';
import { getOnlineBeneficiaries } from '../../api/NetworkingBeneficiaries';
import { getListOfSettlementCurrenciesForPaymentOrders } from "../../api/NetworkingCurrencies";
import { getValueDate, saveDealHeader, validateCustomerAndContactLimits } from "../../api/NetworkingDeals";
import useCurrencyPairs from "../../api/hooks/useCurrencyPairs";
import useCustomerCurrencies from "../../api/hooks/useCustomerCurrencies";
import useHolidays from "../../api/hooks/useHolidays";
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { formatDate, formatToCurrency, formatValidInputClass, getTimehhmm, scaleByCurrency, validInputClass } from "../../helpers/FormatHelper";
import { OnlineRightsNames } from "../../helpers/OnlineRightsHelper";
import { getResource } from '../../helpers/ResourcesHelper';
import { default as ButtonCustom } from '../inputs/Button';
import DatePicker from "../inputs/DatePicker";
import Dropdown from "../inputs/Dropdown";
import { InputWithRef } from "../inputs/Input";
import RadioButton from "../inputs/RadioButton";
import { Beneficiary } from "../models/Beneficiary";
import ConfirmationModal from "../others/ConfirmationModal";
import SendApprovals from "../payments/validates/SendApprovals";
import QuoteBox from "../rates/quoteBox/QuoteBox";
import DataGrid from '../table/DataGrid';

interface ICustomerCurrencies {
  payment: any[];
  settlement: any[];
}


const BeneficiaryPay = (props: any) => {

  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiary, setBeneficiary] = useState(null);

  const [lockedSide, setLockedSide] = useState('sell');
  const [currencyPairs] = useCurrencyPairs();
  const [holidays] = useHolidays();
  const [customerCurrencies] = useCustomerCurrencies();
  const [currenciesByBene, setCurrenciesByBene] = useState([]);
  const [settlementMethods, setSettlementMethods] = useState([{ label: getResource('SelectSettlementMethod'), value: '' }]);
  const [settlementMethod, setSettlementMethod] = useState({} as any);
  const [buyCurrency, setBuyCurrency] = useState('');
  const [sellCurrency, setSellCurrency] = useState('');
  const [valueDate, setValueDate] = useState('');
  const [momentValueDate, setMomentValueDate] = useState(undefined);
  const [momentStartDate, setMomentStartDate] = useState(undefined);
  const [momentEndDate, setMomentEndDate] = useState(undefined);
  const [buyAmount, setBuyAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [quoteAmount, setQuoteAmount] = useState(0.0);
  const [feeAmount, setFeeAmount] = useState(0.0);
  const [validates, setValidates] = useState(false);
  const [showApprovalsPopUp, setShowApprovalsPopUp] = useState(false);
  const [custApprovals, setApprovals] = useState('');
  const [scaleBuyAmount, setScaleBuyAmount] = useState(2);
  const [scaleSellAmount, setScaleSellAmount] = useState(2);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [dealToConfirmAcceptance, setDealToConfirmAcceptance] = useState({} as any);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState('');

  const contactID = localStorage.getItem('ContactID');
  const [customerID, setCustomerID] = useState(0);//JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];

  const [defaultSettlementInstrument, setDefaultSettlementInstrument] = useState('');// = JSON.parse(localStorage.getItem('UserSession')!)['DefaultSettlementInstrument'];
  const [onlineInterface, setOnlineInterface] = useState('');// JSON.parse(localStorage.getItem('UserSession')!).OnlineInterface;

  //for approvals
  const [customerRateDeal, setCustomerRateDeal] = useState(0.0);
  const [dealID, setDealID] = useState(0);

  //after contact limit validation
  const [objDealHeaderAfterValidation, setObjDealHeaderAfterValidation] = useState({});
  const [showConfirmModalValidation, setShowConfirmModalValidation] = useState<boolean>(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');

  //rights
  let OL_SpotAddNew = useOnlineRights(OnlineRightsNames.OL_SpotAddNew);
  let OL_SpotGetQuote = useOnlineRights(OnlineRightsNames.OL_SpotGetQuote);

  const userSession = JSON.parse(localStorage.getItem('UserSession')!);
  const [load, setLoad] = useState(false);

  const inputRefSellAmount = useRef(null)
  const inputRefBuyAmount = useRef(null)

  useEffect(() => {
    setLockedSide('buy')
  }, []);

  useEffect(() => {
    if (lockedSide === 'buy') {
      //@ts-ignore    
      inputRefSellAmount.current.focus()
    }
    else {
      //@ts-ignore    
      inputRefBuyAmount.current.focus()
    }
  }, [lockedSide, beneficiary]);

  const onBeneficiaryClick = (beneficiary: any) => {
    setBeneficiary(beneficiary);
    getCurrenciesByBeneficiary(beneficiary);
    onNewTradeClick();
    setLockedSide('sell')
  }


  const loadBeneficiaries = () => {
    if (userSession != null && userSession.CustomerID > 0) {
      getOnlineBeneficiaries(userSession.CustomerID).then((response) => {
        setBeneficiaries(response.Beneficiaries);
        setLoad(true);
      });
    }
  }

  const columns: ColumnsType<Beneficiary> = [
    {
      title: getResource('Js_TH_Beneficiary'),
      dataIndex: 'BeneficiaryName',
      key: 'BeneficiaryName',
      className: 'ant-table-cell',
      //@ts-ignore
      render: (text, record) => {
        var beneName =
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', alignItems: 'center' }}>
            <div style={{ gridColumn: '1 / 3' }}>{text}</div>
            <div style={{ textAlign: 'right', gridColumn: '3' }}><Button id="button-pay" type="primary" onClick={() => { onBeneficiaryClick(record); }}>Pay</Button></div>
          </div>;
        return beneName;
      },
      sorter: (a, b) => a.BeneficiaryName.localeCompare(b.BeneficiaryName)
    }
  ];


  useEffect(() => {
    loadBeneficiaries();
  }, [load]);

  useEffect(() => {

    const userSession = JSON.parse(localStorage.getItem('UserSession')!);

    if (userSession) {
      if (userSession['CustomerID']) {
        setCustomerID(Number(userSession['CustomerID']));
      }
      if (userSession['DefaultSellCurrencyID']) {
        setBuyCurrency(userSession['DefaultSellCurrencyID']);
      }
      if (userSession['DefaultBuyCurrencyID']) {
        setSellCurrency(userSession['DefaultBuyCurrencyID']);
      }
      if (userSession['DefaultSettlementInstrument']) {
        setDefaultSettlementInstrument(userSession['DefaultSettlementInstrument']);
      }
      if (userSession['OnlineInterface']) {
        setOnlineInterface(userSession['OnlineInterface']);
      }
    }

  }, [localStorage.getItem('UserSession')])

  const calculateEndDate = (startDate: string | Date, buyCurrencyCalculate: string, sellCurrencyCalculate: string) => {
    //Get spot days
    let spotDays = 2;
    //@ts-ignore
    if (currencyPairs[buyCurrencyCalculate + sellCurrencyCalculate] !== undefined) {
      //@ts-ignore
      spotDays = currencyPairs[buyCurrencyCalculate + sellCurrencyCalculate].SpotDays;
    }
    //Recent holidays
    var holidaysHelper = [];
    for (var i = 0; i < holidays.length; i++) {
    //@ts-ignore
      if (
      //@ts-ignore
        holidays[i].CurrencyID === 'ANY' ||
        //@ts-ignore
        holidays[i].CurrencyID === sellCurrency ||
        //@ts-ignore
        holidays[i].CurrencyID === buyCurrencyCalculate
      ) {
        //@ts-ignore
        holidaysHelper.push(holidays[i].Date);
      }
    }

    //Calculate end date
    var endDate = new Date(startDate);
    var daysToAdd = spotDays;

    while (daysToAdd > 0) {
      endDate.setDate(endDate.getDate() + 1);
      if (endDate.getDay() === 6 || endDate.getDay() === 0 || holidaysHelper.includes(formatDate(endDate))) {
        //Nothing at all
      } else {
        daysToAdd--;
      }
    }
    return endDate;
  };

  const getValueDateForCalendar = () => {
    if (sellCurrency !== '' && buyCurrency !== '') {
      let cutoffTime = localStorage.getItem('CutoffTime');

      if (cutoffTime === null) {
        cutoffTime = '12:00';
      }

      let currentDate = new Date();
      let startDate = new Date(localStorage.getItem('SystemDate')!);

      startDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), 0);

      if (getTimehhmm(startDate) > cutoffTime) {
        startDate.setDate(startDate.getDate() + 1);
      }

      let endDate = calculateEndDate(startDate, sellCurrency, buyCurrency);

      setMomentStartDate(moment(startDate) as any);
      setMomentEndDate(moment(endDate) as any);

      let afexSession = JSON.parse(localStorage.getItem('UserSession')!);
      if (afexSession.OnlineInterface != null && afexSession.OnlineInterface.includes('AFEX - Pass Thru')) {
        getValueDate(customerID, sellCurrency + buyCurrency).then((valueDateResponse: any) => {
          if (valueDateResponse.httpStatusCode !== 200) {
          } else {
            if (valueDateResponse.error != undefined && valueDateResponse.error != '') {
            } else {
              if (valueDateResponse.valueDate != undefined) {
                let date = Date.parse(valueDateResponse.valueDate);
                console.log(date);
                setMomentValueDate(moment(date) as any);
                setValueDate(formatDate(valueDateResponse.valueDate));
              }
            }
          }
        });
      } else {
        setMomentValueDate(moment(startDate) as any);
        setValueDate(formatDate(startDate));
      }
    }
  };

  useEffect(() => {

    getValueDateForCalendar();

  }, [buyCurrency, sellCurrency]);

  const validateFields = () => {
    var fields = [
      validInputClass(buyAmount.toString()),
      validInputClass(sellAmount.toString()),
      settlementMethod.value]

    fields.forEach(element => {
      if (!element) {
        setValidates(true)
        return true;
      } else {
        setValidates(false)
        return false;
      }
    })
  }

  const disabledDate = (current: any) => {
    return current < momentStartDate! || current > momentEndDate! || moment(current).day() === 0 || moment(current).day() === 6;
  };

  const radioChangeHandler = (event: any) => {
    setLockedSide(event.target.value);
    if (event.target.value === 'buy') {
      setBuyAmount(0);
      setQuoteAmount(0.0);
    } else {
      setSellAmount(0);
      setQuoteAmount(0.0);
    }
  };

  const onChangeValueDate = (date: any, dateString: string) => {
    setValueDate(dateString);
    setMomentValueDate(date);
  };

  const dropdownSettlementsChangeHandler = (item: any, name: string) => {
    setSettlementMethod(item);
    //setValidates(false)
    console.log(item);
  };

  const dropdownBuyChangeHandler = (item: any, name: string) => {
    setScaleBuyAmount(item.NumberOfDecimals);
    setBuyCurrency(item.value);
  };

  const dropdownSellChangeHandler = (item: any, name: string) => {
    setScaleSellAmount(item.NumberOfDecimals);
    setSellCurrency(item.value);
    setSettlementMethod({});
    getListOfSettlementMethods(item.value);
  };

  const getListOfSettlementMethods = async (currencyID: string) => {
    let jsonResponse = await getListOfSettlementCurrenciesForPaymentOrders(customerID, currencyID);
    if (jsonResponse && jsonResponse.items) {
      const formattedSettlemnts = jsonResponse.items.map((c: any) => {
        const details = c.details.map((d: any) => {
          if (d.label === 'Balance') {
            return { ...d, description: formatToCurrency(d.description, sellCurrency) }
          }
          return d;
        });
        return ({ ...c, label: c.name, details: details });
      });
      formattedSettlemnts.push({ label: getResource('SelectSettlementMethod'), value: '' });
      setSettlementMethods(formattedSettlemnts);

      formattedSettlemnts.forEach((element: any) => {
        if (element.value.includes(defaultSettlementInstrument)) {
          setSettlementMethod(element);
          return;
        }
      });
    }   
    else {
      setSettlementMethods([]);
      setSettlementMethod({ value: '' });
    }
  };

  const onChangeBuyAmount = (value: number) => {
    if (lockedSide === 'sell') {
      setBuyAmount(value);
      setValidates(false)
      setQuoteAmount(value);
    }
  };

  const onChangeSellAmount = (value: number) => {
    if (lockedSide === 'buy') {
      setSellAmount(value);
      setValidates(false)
      setQuoteAmount(value);
    }
  };

  const amountCalc = (amount: number) => {
    console.log(amount);
    if (lockedSide === 'buy') {
      setBuyAmount(amount);
    } else {
      setSellAmount(amount);
    }
  };

  const onNewTradeClick = () => {
    setLockedSide('sell');
    setSettlementMethods([{ label: getResource('SelectSettlementMethod'), value: '' }]);
    setBuyCurrency(JSON.parse(localStorage.getItem('UserSession')!)['DefaultSellCurrencyID']);
    setSellCurrency(JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID']);
    getListOfSettlementMethods(JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID']);
    //setValueDate('');
    setBuyAmount(0);
    setSellAmount(0);
    setQuoteAmount(0.0);
    setSettlementMethod({ label: getResource('SelectSettlementMethod'), value: '' });
    //setMomentValueDate(undefined);
  };

  const onCancelQuoteClick = () => {
    if (lockedSide === 'buy') {
      setBuyAmount(0);
    } else {
      setSellAmount(0);
    }
  };

  const onAcceptClick = async (rateQuoteID: string, customerRate: number, marketRate: number, profit: number, coverCustomerID: number, jsonProperties: string) => {
    showLoadingModal();
    let fixedLockedSide;
    if (lockedSide === 'buy') {
      fixedLockedSide = 'Buy';
    } else {
      fixedLockedSide = 'Sell';
    }
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    let array = settlementMethod.value.split(';');
    let deliveryMethod = settlementMethod.details.filter((i: any) => i.label.includes('Delivery Method'));
    let outgoingFunds = [];

    let beneAux = beneficiary!["BankAccounts"] as [];
    let bankAux = beneAux.filter((ccy: any) => ccy["CurrencyID"] === sellCurrency);
    let bankAccountID = 0;
    if (bankAux && bankAux.length > 0) {
      bankAccountID = bankAux[0]["BankAccountID"];
    }

    let outgoing = {
      DealHeaderID: 0,
      ValueDate: formatDate(props.valueDate),
      UpdateToken: 0,
      BeneficiaryID: beneficiary!["BeneficiaryID"],
      BeneficiaryBankAccountID: bankAccountID,
      BeneficiaryType: beneficiary!["BeneficiaryType"],
      Equivalent: lockedSide === 'sell' ? sellAmount.toFixed(scaleByCurrency(sellCurrency)) : buyAmount.toFixed(scaleByCurrency(buyCurrency)),
      EntityProperties: {},
      EntityPropertiesJson: '',
      OutgoingFundsID: 0,
      PaymentAmount: lockedSide === 'sell' ? buyAmount.toFixed(scaleByCurrency(buyCurrency)) : sellAmount.toFixed(scaleByCurrency(sellCurrency)),
      PaymentInstrument: beneficiary!["PreferredMethodOfPayment"],
      PaymentReference: '',
      ShowProperties: false,
      Status: 'Pending',
      WireItemFee: 0,
      WireItemFeeCurrencyID: buyCurrency,
    }
    outgoingFunds.push(outgoing);


    let objDealHeader = {
      BuyAmount: sellAmount.toFixed(scaleByCurrency(sellCurrency)),
      BuyCurrency: sellCurrency,
      CoverCustomerID: coverCustomerID,
      CreatedBy: localStorage.getItem('UserID'),
      CustomerContactID: localStorage.getItem('ContactID'),
      CustomerID: userSession.CustomerID,
      CustomerRate: customerRate,
      CustomerRefNo: '',
      ExternalInterface: userSession.OnlineInterface,
      ExternalInterfaceType: userSession.OnlineInterfaceType,
      IncomingFunds: [] as any,
      LockedSide: fixedLockedSide,
      MarketRate: marketRate,
      OutgoingFunds: outgoingFunds,
      ProfitAmount: profit,
      RateQuoteID: rateQuoteID,
      SellAmount: buyAmount.toFixed(scaleByCurrency(buyCurrency)),
      SellCurrency: buyCurrency,
      Status: userSession.DealApproveRequired ? 'Pending for Approval' : 'Accepted',
      DealOrigin: 'Online Deal',
      DealType: 1,
      UpdateBuyAmountField: fixedLockedSide === 'Sell' ? true : false,
      UpdatedBy: localStorage.getItem('UserID'),
      ValueDate: valueDate,
      DealHeaderID: 0,//New Deal,
      SettlementDeliveryMethods: deliveryMethod && deliveryMethod.length > 0 ? deliveryMethod[0].translationlabel : '',
      JsonProperties: jsonProperties
    };

    objDealHeader.IncomingFunds.push({
      Amount: sellAmount,
      BankAccountID: array[1],
      SettlementInstrument: array[2],
      SettlementDeliveryMethod: deliveryMethod && deliveryMethod.length > 0 ? deliveryMethod[0].translationlabel /*deliveryMethod[0].description*/ : ''
    });

    setObjDealHeaderAfterValidation(objDealHeader);

    let responseLimit = await validateCustomerAndContactLimits(objDealHeader.DealType, buyAmount, buyCurrency, objDealHeader.CustomerID, Number(objDealHeader.CustomerContactID));
    let alertTitle = '';
    let alertMessage = '';

    if (responseLimit.httpStatusCode === 200) {
      if (responseLimit.ValidationMessage === '') {
        callSaveDealHeader(objDealHeader);
      }
      else {
        if (responseLimit.LimitValidationStatus.toLowerCase() === 'warning') {
          setConfirmationModalTitle(getResource('Title.Warning'));
          setConfirmationModalMessage(responseLimit.ValidationMessage);
          setShowConfirmModalValidation(true);
        }
        else {
          alertTitle = getResource('Js_MessageError_TitleError');
          alertMessage = responseLimit.ValidationMessage;
          showModal(alertTitle, alertMessage);
          hideLoadingModal();
        }
      }
    }
    else {
      alertTitle = getResource('Js_MessageError_TitleError');
      alertMessage = getResource('Js_Message_TryAgainOrContactAdministrator');
      showModal(alertTitle, alertMessage);
      hideLoadingModal();
    }

    //callSaveDealHeader(objDealHeader);
  };

  const callSaveDealHeader = (objDealHeader: any) => {
    let array = settlementMethod.value.split(';');
    let deliveryMethod = settlementMethod.details.filter((i: any) => i.label.includes('Delivery Method'));

    saveDealHeader(objDealHeader).then((jsonDealResponse) => {
      //alert(jsonDealResponse.stringify(json));
      hideLoadingModal();
      var alertTitle = '', alertMessage = '';
      switch (Number(jsonDealResponse.httpStatusCode)) {
        case 200:
          objDealHeader.DealHeaderID = jsonDealResponse.DealHeaderID;
          objDealHeader.Status = jsonDealResponse.Status;
          setDealID(jsonDealResponse.DealHeaderID);
          var approvals = jsonDealResponse.ApprovalsJson;
          //var showApprovalsPopUp = false;
          alertTitle = getResource('Js_MessageError_TitleSuccess');
          alertMessage = getResource('Message_DealSaved').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

          switch (jsonDealResponse.Status) {
            case 'Accepted':
              alertMessage = getResource('Message_OrderCommitted').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

              if (jsonDealResponse.HeldFundBalance != null && jsonDealResponse.HeldFundBalance > -1) {
                alertMessage = alertMessage + " " + getResource('YourCurrentBalance') + ": " + formatToCurrency(jsonDealResponse.HeldFundBalance.toString(), sellCurrency) + ' ' + sellCurrency;
                getListOfSettlementMethods(sellCurrency);
              }
              if (jsonDealResponse.LimitValidationMessage != undefined && jsonDealResponse.LimitValidationMessage != undefined) {
                alertMessage = alertMessage + " , " + jsonDealResponse.LimitValidationMessage;
              }
              //--this.sendDealEmail(jsonDealResponse.DealHeaderID);
              //this.sendConfirmationDirect(jsonDealResponse.DealHeaderID);
              onCancelClick();
              showModal(alertTitle, alertMessage);
              break;
            case 'Pending for Approval':
              if (approvals === undefined) {
                approvals = '';
              }
              if (approvals === '') {
                alertTitle = getResource('Js_MessageError_TitleError');
                alertMessage = getResource('Message_DealMissingApprovals');
                setShowApprovalsPopUp(false);
                onCancelClick();
                showModal(alertTitle, alertMessage);
              } else {
                setApprovals(approvals);
                setCustomerRateDeal(objDealHeader.CustomerRate);
                setShowApprovalsPopUp(true);
              }
              break;
            case 'Pending':
              alertMessage = getResource('Message_OrderSavedAsPending').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

              let complianceRulesFound = jsonDealResponse != undefined &&
                ((jsonDealResponse.ComplianceSoftRules !== undefined && jsonDealResponse.ComplianceSoftRules !== null
                  && Array.isArray(jsonDealResponse.ComplianceSoftRules) && jsonDealResponse.ComplianceSoftRules.length > 0)
                  ||
                  (
                    jsonDealResponse.ComplianceRules !== undefined && jsonDealResponse.ComplianceRules !== null
                    && Array.isArray(jsonDealResponse.ComplianceRules) && jsonDealResponse.ComplianceRules.length > 0)
                )

              if (complianceRulesFound) {
                alertMessage = getResource('Message_DealCompliance');
              } else {
                alertMessage = alertMessage + jsonDealResponse.MessagePending;
              }

              showModal(alertTitle, alertMessage);
              onNewTradeClick()

              break;
            default:
              alertMessage = getResource('Message_OrderSaved').replace('DealHeaderID', jsonDealResponse.DealHeaderID);
              onCancelClick();
              showModal(alertTitle, alertMessage);
              break;
          }
          break;
        default:
          alertTitle = getResource('Js_MessageError_TitleError');
          alertMessage = getResource('Message_DealError');

          if (jsonDealResponse.ValidationMessage !== undefined) {
            //validation message from API
            alertTitle = getResource('Js_MessageError_TitleValidation');
            alertMessage = jsonDealResponse.ValidationMessage;
          }
          else {
            if (jsonDealResponse.httpErrorMessage !== undefined) {
              //message from networking based on status code
              alertMessage = jsonDealResponse.httpErrorMessage;
            }
          }
          if (jsonDealResponse.Message === 'SentToInterface') {
            alertMessage = getResource('Message_DealErrorSentInterface');
          }

          onCancelClick();
          showModal(alertTitle, alertMessage);
          break;
      }
    });
  }

  const closeSendEmailApproval = () => {
    setShowApprovalsPopUp(false);
    var alertTitle = '', alertMessage = '';
    let array = settlementMethod.value.split(';');
    let deliveryMethod = settlementMethod.details.filter((i: any) => i.label.includes('Delivery Method'));

    alertTitle = getResource('Js_MessageError_TitleSuccess');
    alertMessage = getResource('Message_CreditApproval');
    showModal(alertTitle, alertMessage);
    props.onAcceptTrade(dealID, buyCurrency, sellCurrency, buyAmount, valueDate, array[2], deliveryMethod, customerRateDeal, true);
  }

  const closeConfirmationModal = () => {
    setShowConfirmModal(false)
    setDealToConfirmAcceptance({})
    setConfirmationModalTitle('')
    onNewTradeClick()
  }

  const acceptDealInConfirmationModal = () => {
    setShowConfirmModal(false)
    console.log(dealToConfirmAcceptance)
    showLoadingModal();
    callSaveDealHeader(dealToConfirmAcceptance);
  }

  const yesLimitValidationAlert = () => {

    let objDealHeader = objDealHeaderAfterValidation;

    setShowConfirmModalValidation(false);
    callSaveDealHeader(objDealHeader);
  }

  const closeLimitValidationAlert = () => {
    hideLoadingModal();
    setShowConfirmModalValidation(false);
  }

  const rowClassName = (record: any) => {
    return beneficiary && record!["BeneficiaryID"] === beneficiary!["BeneficiaryID"]
      ? "selected-row"
      : "";
  };

  const beneficiariesList = () => {
    return (
      <div className='col-md-4'>
        <DataGrid
          className=""
          columns={columns}
          dataSource={beneficiaries}
          rowClassName={rowClassName}
        />
      </div>
    );
  }

  const getCurrenciesByBeneficiary = (beneficiary: any) => {
    let currencies = (customerCurrencies as ICustomerCurrencies).settlement;
    let newCurrencies = [] as any;
    if (beneficiary !== null) {
      let beneCcy = beneficiary!["BankAccounts"] as any[];
      if (beneCcy && beneCcy.length > 0) {
        currencies.forEach(c => {
          let auxCCy = beneCcy.filter((ccy: any) => ccy["CurrencyID"] === c.CurrencyID);
          if (auxCCy && auxCCy.length > 0) {
            newCurrencies.push(c);
          }
        }
        )
      }
    }
    setCurrenciesByBene(newCurrencies);
  }

  const onCancelClick = () => {
    onNewTradeClick();
    setBeneficiary(null);
    setCurrenciesByBene([]);
  }

  return (
    <div className="row">

      {beneficiariesList()}

      <div className='col-md-8'>
        < div className="" >
          <div className='col-md-12 col-xs-12'>

            <div className="row  center-xs">
              <div className="col-md-4 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.BuyCurrency')}</span>
                </div>
                <div className="row">
                  <Dropdown name="buyCurrency" title={getResource('SelectCurrencyBuy')} list={(customerCurrencies as ICustomerCurrencies).payment} onChange={dropdownBuyChangeHandler} select={{ value: buyCurrency }} />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="row between-xs">
                  <div className="col-xs-5" style={{ padding: 0 }}>
                    <span className="input-label" style={{ justifyContent: 'flex-start' }}>
                      {getResource('Form.Label.BuyAmount')}
                    </span>
                  </div>
                  <div className="col-xs-4">
                    <RadioButton changed={radioChangeHandler} id="1" isSelected={lockedSide === 'sell'} label={getResource('Lock')} value="sell" />
                  </div>
                </div>
                <div className="row">
                  <InputWithRef className={!validates ? '' : (lockedSide === 'buy' ? '' : formatValidInputClass(validInputClass(buyAmount.toString())))} type="number"
                    disabled={lockedSide === 'buy'} value={buyAmount} onValueChange={onChangeBuyAmount} decimalScale={scaleBuyAmount} ref={inputRefBuyAmount} />
                </div>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-md-4 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.SellCurrency')}</span>
                </div>
                <div className="row">
                  <Dropdown
                    name="sellCurrency"
                    title={getResource('SelectCurrencySell')}
                    list={currenciesByBene}
                    onChange={(item: any, name: string) => {
                      dropdownSellChangeHandler(item, name);
                    }}
                    select={{ value: sellCurrency }}
                    disabled={props.fromBalances}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="row between-xs">
                  <div className="col-xs-5" style={{ padding: 0 }}>
                    <span className="input-label" style={{ justifyContent: 'flex-start' }}>
                      {getResource('Form.Label.SellAmount')}
                    </span>
                  </div>
                  <div className="col-xs-4">
                    <RadioButton changed={radioChangeHandler} id="2" isSelected={lockedSide === 'buy'} label={getResource('Lock')} value="buy" />
                  </div>
                </div>
                <div className="row">
                  <InputWithRef className={!validates ? '' : (lockedSide === 'sell' ? '' : formatValidInputClass(validInputClass(sellAmount.toString())))} type="number"
                    disabled={lockedSide === 'sell'} value={sellAmount} onValueChange={onChangeSellAmount} decimalScale={scaleSellAmount} ref={inputRefSellAmount} />
                </div>
              </div>
            </div>
            <div className="row center-xs">
              <div className="col-md-4 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.ValueDate')}</span>
                </div>
                <div className="row">
                  <DatePicker key="app-datepicker-valuedate" className={!validates ? '' : (formatValidInputClass(validInputClass(valueDate)))} onChange={onChangeValueDate} value={momentValueDate} disabledDate={disabledDate} />
                </div>
              </div>
              <div className="col-md-4 col-xs-12"></div>
            </div>

            <div className="row center-xs">
              <div className="col-md-8 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.SettlementMethod')}</span>
                </div>
                <div className="row">
                  <Dropdown
                    name="settlementMethod"
                    title={getResource('SelectSettlementMethod')}
                    className={!validates ? '' : (formatValidInputClass(validInputClass(settlementMethod.value)))}
                    settlementMode={true}
                    list={settlementMethods}
                    onChange={dropdownSettlementsChangeHandler}
                    //submit={true}
                    select={{ value: settlementMethod.value }}
                    disabled={sellCurrency === '' || props.fromBalances ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="row-actions">

          <ButtonCustom type="secondary" onClick={onCancelClick}>
            {getResource('Button.Cancel')}
          </ButtonCustom>

          <ButtonCustom type="secondary" onClick={onNewTradeClick}>
            {getResource('NewTrade')}
          </ButtonCustom>
          {OL_SpotGetQuote && OL_SpotAddNew && <QuoteBox
            mode='modal'
            settlementMethod={settlementMethod}
            dealType={1}
            buyCurrency={buyCurrency}
            sellCurrency={sellCurrency}
            customerID={customerID}
            contactID={Number.parseInt(contactID!)}
            valueDate={valueDate}
            forwardNearDate={valueDate}
            amount={quoteAmount}
            lockedSide={lockedSide}
            totalFeesAmount={feeAmount}
            amountCalc={amountCalc}
            onNewTradeClick={onNewTradeClick}
            onAcceptClick={onAcceptClick}
            onCancelQuote={onCancelQuoteClick}
            validateFields={validateFields}
            disabled={beneficiary === null}
          />}
        </div>
        <div className="disclaimer-container">
          <div className="row center-xs">
            <div className="col-xs-12 row rate-disclaimer">
              <span>{getResource('AFEX_Message')}</span>
            </div>
            {(onlineInterface != null && onlineInterface.includes('AFEX - Pass Thru')) &&
              <span className="afex-disclaimer col-xs-12">
                {getResource('AFEX_Message_TC')}
              </span>
            }
          </div>
        </div>
      </div >


      <SendApprovals show={showApprovalsPopUp} dealHeaderID={dealID} onClose={closeSendEmailApproval} approvals={custApprovals} />
      <ConfirmationModal visible={showConfirmModal} cancel={closeConfirmationModal} confirm={acceptDealInConfirmationModal} title={confirmationModalTitle} />
      <ConfirmationModal visible={showConfirmModalValidation} cancel={closeLimitValidationAlert} confirm={yesLimitValidationAlert} mainTitle={confirmationModalTitle} title={confirmationModalMessage} />

    </div>
  );
}

export default BeneficiaryPay;