import React, { forwardRef } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface InputProps {
  id?: string;
  name?: string;
  key?: string;
  value?: any;
  numberProps?: any;
  className?: string;
  disabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onValueChange?: ((value: number) => void) | undefined;
  min?: number;
  max?: number;
  isSelected?: boolean;
  type?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  maxLength?: number;
  submit?: boolean;
  errorMessage?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  decimalScale?: number;
  getInputRef?: ((el: HTMLInputElement) => void) | React.Ref<any>;
}

export const InputWithRef = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Input {...props} getInputRef={ref} />
))

const Input: React.FC<InputProps> = (props) => {
  let input = <div></div>;
  switch (props.type) {
    /*     case 'number':
      input = (
        <InputNumber
          id={props.id}
          className={props.className != null ? props.className : ''}
          style={props.style}
          step="0.01"
          defaultValue={props.value}
          value={props.value}
          formatter={value => {
            const exp = /(\d)(?=(\d{3})+(?!\d))/g;
            const rep = '$1,';
            let arr = value.split('.');
            arr[0] = arr[0].replace(exp, rep);
            if (arr[1] != null) { arr[1] = arr[1].length > 2 ? arr[1].substr(-20, 2) : arr[1]; }
            return arr[1] ? arr.join('.') : arr[0];
          }
          }
          //parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
          onChange={props.onChange}
          stringMode
          disabled={props.disabled}
          onBlur={props.onBlur}
        />)
      break; */
    case 'number':
    case 'number2':
      input = (
        <NumberFormat
          name={props.id}
          id={props.id}
          key={props.key}
          value={props.value}
          thousandSeparator={true}
          prefix={props.numberProps ? props.numberProps.Prefix : ''}
          className={props.className ? props.className : ''}
          inputmode="numeric"
          decimalSeparator="."
          thousandsGroupStyle="thousand"
          onValueChange={(values: NumberFormatValues) => {
            if (props.onValueChange != null) {
              props.onValueChange(values.floatValue as number)
            }
            return undefined;
          }}
          disabled={props.disabled}
          allowNegative={false}
          decimalScale={props.decimalScale != null ? props.decimalScale : 2}
          fixedDecimalScale={true}
          placeholder={props.placeholder != null ? props.placeholder : "0.00"}
          onBlur={props.onBlur}
          onChange={props.onChange}
          isAllowed={(values: NumberFormatValues) => {
            if (values != null && values.floatValue != null) {
              const min = props.min != null ? props.min : values.floatValue;
              const max = props.max != null ? props.max : values.floatValue;
              return min <= values.floatValue && values.floatValue <= max;
            }
            return false;
          }}
          getInputRef={props.getInputRef}
        />
      );
      break;
    default:
      input = (
        <input
          className={props.className != null ? props.className : ''}
          style={props.style}
          id={props.id}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value}
          type={props.type}
          checked={props.isSelected}
          disabled={props.disabled}
          onClick={props.onClick}
          maxLength={props.maxLength}
        />
      );
      break;
  }
  return (
    <>
      {input}
      {((props.type === 'number' && (props.value === undefined || props.value <= 0 || props.value === null || props.value === '' || isNaN(props.value))) ||
        (props.type === 'number2' && (props.value === undefined || props.value === null || props.value === '' || isNaN(props.value))) ||
        ((props.type === 'text' || props.type === 'password') && props.value === '') ||
        (props.type === 'password' && props.errorMessage != null && props.errorMessage.includes('do not match'))) &&
        props.submit && (
          <div className="row">
            <div className="ant-form-item-explain ant-form-item-explain-error">
              <div role="alert">{props.errorMessage === undefined ? 'Required!' : props.errorMessage}</div>
            </div>
          </div>
        )}
    </>
  );
};

export default Input;